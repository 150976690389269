import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch';

import { contentBlocksToText } from 'polaris-coreweb/exports';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroup,
} from 'SharedPartialsLocal';

// Template styles and imports
import 'Styles/components/templates/_NotFound.scss';

const NotFound = ({
  layoutData: { mostPopular },
}) => (
  <div className="polaris__body -not-found-template">
    <main className="polaris__main" id="main">
      <div className="polaris__core-content polaris__content -center -body-copy">
        <Heading size={2}>
          Sorry! This page cannot be found
        </Heading>

        <p>
          It looks like this page has moved somewhere else but you can try searching for what you are looking for
        </p>

        <div className="polaris__gray--bg">
          <SimpleSearch
            label=""
            inputId="polaris__not-found--template-input"
            placeholder="Search..."
            extraClassNames={{
              'polaris__search--results': true,
              '-no-padding-margin': true,
            }}
          />
        </div>
      </div>

      {mostPopular
      && (
      <div className="polaris__core-content polaris__content">
        {getArticleGroup({
          title: 'Most Popular',
          content: mostPopular.map(item => ({ ...item, excerpt: contentBlocksToText(item.excerpt) })),
          extraClassNames: { 'polaris__page--most-popular': true },
          articleCardProps: {
            isHeading: false,
            showRating: true,
          },
        })}
      </div>
      )}
    </main>
  </div>
);

NotFound.propTypes = {
  layoutData: PropTypes.shape({
    mostPopular: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default compose(
  withTemplate,
  withHelmet('notFound'),
)(NotFound);
