import React from 'react';
import PropTypes from 'prop-types';
import { getLoadMore } from 'SharedPartials/getLoadMore';
import { getIndexPageItems } from 'SharedPartials/getIndexPageItems';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import {
  getBreadcrumbs,
  getTaxonomyFilter,
} from 'SharedPartialsLocal';

// Template styles and imports
import 'Styles/components/templates/_IndexTemplate.scss';

const IndexTemplate = ({
  layoutData: {
    page: {
      breadcrumbs,
      filters,
      showFilters,
      sponsor: sponsorInfo,
      title,
      url,
    },
  },
  pagination: {
    hasMore,
    isLoading,
    loadMore,
    nextUrl,
    page: curPage,
    pages,
    setPageRef,
  },
  config: {
    adBreakPoints,
    breakpoints,
    globalSettings: {
      promoBoxSettings,
    },
  },
}) => {
  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const config = {
    promoBoxConfig: {
      ...promoBoxSettings,
      extraClassNames: {
        '-index': true,
      },
      imageSize: 'subscription',
      ppc: promoBoxSettings.subscriptionIndex,
    },
    adBreakPoints,
  };

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See more',
      seeLessLabel: 'See less',
      icon: 'caret-down',
    },
    currentPageUrl: url,
    content: taxonomyFilters,
  };

  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
    href: nextUrl,
  };

  return (
    <div className="polaris__body">
      <main className="polaris__main" id="main">
        <div className="polaris__core-content polaris__content polaris__heading-block">
          <div className="polaris__heading-block--inner">
            {getBreadcrumbs(breadcrumbs, false, '', title)}
          </div>
        </div>

        {showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0 && (
          <div className="polaris__taxonomy-filter--wrapper">
            <div className="polaris__core-content polaris__content">
              {getTaxonomyFilter(taxonomyFilterParams)}
            </div>
          </div>
        )}

        <div className="polaris__content">
          <div data-previous="1" ref={setPageRef} />
          {getIndexPageItems({
            articlesPerRow: 3,
            articleCardProps: {
              datePosition: 'bottom',
              showRating: true,
            },
            config,
            extraClassNames: { '-news-index': true, 'polaris__core-content': true },
            newsletterPosition: false,
            nativeAdPosition: 5,
            pages,
            promoboxPosition: 5,
            setPageRef,
            templateType: 'indexTemplate',
            isMobile,
          })}
          <div className="polaris__core-content">
            {hasMore && getLoadMore(loadMoreParams)}
          </div>
        </div>
      </main>
    </div>
  );
};

IndexTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    nextUrl: PropTypes.string,
    page: PropTypes.number,
    pages: PropTypes.object,
    setPageRef: PropTypes.func,
  }).isRequired,
  config: PropTypes.shape({
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    adBreakPoints: PropTypes.object,
    globalSettings: PropTypes.shape({
      promoBoxSettings: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default IndexTemplate;
