import React, { Fragment } from 'react';
import { processNodes } from 'react-html-parser';
import he from 'he';
import uniqid from 'uniqid';

import { HtmlSafe } from 'polaris-coreweb/exports';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { ShopWindow } from '@autovia-uk/polaris-components/components/atoms/ShopWindow';
import { Text } from '@autovia-uk/polaris-components/components/atoms/Text';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { List } from '@autovia-uk/polaris-components/components/molecules/List';
import { SocialEmbed } from '@autovia-uk/polaris-components/components/molecules/SocialEmbed';
import { RelatedLinks } from '@autovia-uk/polaris-components/components/molecules/RelatedLinks';
import { Gallery } from '@autovia-uk/polaris-components/components/organisms/Gallery';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';
import { Video } from '@autovia-uk/polaris-components/components/molecules/Video';
import { IFrame } from '@autovia-uk/polaris-components/components/atoms/IFrame';
import {
  getNewsletter,
  getPromoBoxSettings,
  getSubscription,
  getPodcast,
  getReviewList,
} from 'SharedPartialsLocal';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { SideContent } from '@autovia-uk/polaris-components/components/molecules/SideContent';
import { ViewportChecker } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import { getDigiteka } from 'SharedPartials/getDigiteka';
import { Ceros } from '@autovia-uk/polaris-components/components/atoms/Ceros';
import { AccordionFAQs } from '@autovia-uk/polaris-components/components/organisms/AccordionFAQs';
import { ValuationToolWidget } from '@autovia-uk/polaris-components/components/organisms/ValuationToolWidget';


// Get the classes from a string of classes.
const getClasses = (classesStr) => {
  if (!classesStr) {
    return {};
  }

  return classesStr
    .split(' ')
    .reduce((acc, next) => ({ ...acc, [next]: true }), {});
};

// eslint-disable-next-line consistent-return
export const getComponentForBlockType = (options) => {
  const {
    type,
    block,
    data,
    index,
    embedTypes,
    mappedContentType,
    template,
    isAmp,
    layoutType,
  } = options;

  const props = {
    key: `body_block_${index}`,
    mappedContentType,
  };

  const transforms = {
    /* eslint-disable react/prop-types */
    a: ({
      attribs: {
        id,
        href,
        name,
        rel,
        target,
        extraClassNames,
      },
      attribs: allAttributes,
      children,
    }) => {
      // We have to getClasses here because we aren't passing them properly from htmlSafe
      const classes = getClasses(allAttributes.class);

      return (
        <Link
          href={href}
          id={id}
          key={uniqid('link-')}
          name={name}
          rel={rel}
          target={target}
          extraClassNames={{
            ...extraClassNames,
            ...classes,
          }}
        >
          {processNodes(children)}
        </Link>
      );
    },
    /* eslint-enable */
  };

  switch (type) {
    case 'TEXT': {
      return (
        <Text
          {...props}
          span={false}
        >
          <HtmlSafe
            html={he.decode(data)}
            transforms={transforms}
            key={uniqid()}
          />
        </Text>
      );
    }

    case 'CEROS': {
      return (
        <Ceros
          html={he.decode(data)}
          key={`ceros-${index}`}
        />
      );
    }

    case 'HEADER': {
      const { size, text } = data;
      const headingSize = Number.parseInt(size, 10) || 3;

      return (
        <Heading
          {...props}
          size={headingSize}
        >
          {he.decode(text)}
        </Heading>
      );
    }

    case 'IFRAME': {
      return (
        <IFrame
          key={uniqid()}
          iframeUrl={data.src}
          iframeHeight={data.height}
          iframeWidth={data.width}
          scrolling={data.scrolling}
        />
      );
    }

    case 'IMAGE': {
      props.credits = data.credit;

      // set correct image size for resource download
      props.size = 'content-image';
      if (template === 'resource-download') {
        props.size = 'resource-image';
      }

      return (
        <Image
          {...data}
          {...props}
          displayImageMetadata
        />
      );
    }

    case 'TABLE': {
      return (
        <Table
          key={uniqid()}
          {...props}
        >
          <HtmlSafe html={he.decode(data)} />
        </Table>
      );
    }

    case 'SUBSCRIBE': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      const {
        promoBoxSettings,
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        }, viewport,
      } = block.props;

      const subscriptionBlock = (
        promoBoxSettings && getSubscription(getPromoBoxSettings({
          promoBoxSettings,
          except: promoBoxSettings.longText,
          ppc: promoBoxSettings.subscriptionArticle,
        }))
      );

      if (isAmp && viewport === 'mobile') {
        return subscriptionBlock;
      }

      // ViewportChecker is not supported on AMP
      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('viewportchecker-')}
        >
          {subscriptionBlock}
        </ViewportChecker>
      );
    }

    case 'NEWSLETTER': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      const {
        newsletterConfig,
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        }, viewport,
      } = block.props;

      // Newsletter and ViewportChecker are not supported on AMP
      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('viewportchecker-')}
        >
          {getNewsletter({ newsletterConfig })}
        </ViewportChecker>
      );
    }

    case 'SUBSCRIBE_NEWSLETTER': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      const {
        promoBoxSettings,
        newsletterConfig,
        breakpoints: {
          desktop: desktopBreakpoint,
          mobile: mobileBreakpoint,
        }, viewport,
      } = block.props;

      const subscriptionBlock = (
        promoBoxSettings && getSubscription(getPromoBoxSettings({
          promoBoxSettings,
          ppc: promoBoxSettings.subscriptionArticle,
          except: promoBoxSettings.longText,
          mainTitle: null,
        }))
      );

      if (isAmp && viewport === 'mobile') {
        return subscriptionBlock;
      }

      // ViewportChecker is not supported on AMP
      return (
        <ViewportChecker
          range={viewport === 'desktop' ? desktopBreakpoint : mobileBreakpoint}
          key={uniqid('viewportchecker-')}
        >
          {subscriptionBlock}
          {getNewsletter({
            newsletterConfig,
            extraClassNames: {
              newsletter__class: true,
            },
          })}
        </ViewportChecker>
      );
    }

    case 'SHOPWINDOW': {
      const {
        props: {
          viewport,
        },
      } = block;

      if (isAmp || viewport === 'mobile') return '';

      return (
        <ShopWindow />
      );
    }

    case 'SOCIAL_EMBED': {
      if (!data.html) {
        return false;
      }

      // Check for youtube video
      if (data.provider_name === 'YouTube' && data.__data && data.__data.url) {
        return (
          <Video url={data.__data.url} key={uniqid()} />
        );
      }

      // Check for SoundCloud
      if (data.provider_name === 'SoundCloud') {
        // get iframe src
        const iframeURLRegex = new RegExp('src="(.*?)"', 'gm');
        const iframeURLMatch = iframeURLRegex.exec(data.html);

        if (!iframeURLMatch || !iframeURLMatch[1]) {
          return false;
        }

        const podCastData = {
          podcastUrl: iframeURLMatch[1],
        };

        return getPodcast(podCastData);
      }

      embedTypes.add(data.provider_name);

      return (
        <SocialEmbed
          {...props}
          type={data.provider_name}
          url={data.__data.url}
        >
          <HtmlSafe html={data.html} />
        </SocialEmbed>
      );
    }

    case 'RELATED_CONTENT': {
      const blockData = JSON.parse(block.data);
      let componentData = {};
      if (blockData && blockData.length && Array.isArray(blockData)) {
        componentData = blockData.map((bd) => {
          const img = bd.image || null;
          if (img) {
            img.size = 'related-embed';
          }
          return (
            {
              url: bd.url || '',
              label: bd.label || '',
              image: img,
            }
          );
        });
      }
      return (
        <RelatedLinks
          content={componentData}
          {...props}
        />
      );
    }

    case 'IndexBlock': {
      return (
        <List
          content={block.items}
          {...props}
        />
      );
    }

    case 'SIDE_CONTENT': {
      const sideContenData = block && block.props && Array.isArray(block.props) && block.props[0];
      const associatedContent = sideContenData && sideContenData.children;
      const isArray = Array.isArray(associatedContent);

      if (!isArray || (isArray && associatedContent.length === 0)) return false;

      return (
        <SideContent
          key={uniqid()}
          title={sideContenData.title}
          titleSize={4}
        >
          {associatedContent.map((child, iterator) => (
            <Fragment key={`sideContent_${iterator}`}>
              <Heading
                key={uniqid()}
                size={5}
              >
                {child.title}

              </Heading>
              <ul className="polaris__list">
                {child.items.map((item, listIterator) => <li key={`sideContentItem_${listIterator}`}><a href={item.url}>{item.title}</a></li>)}
              </ul>
            </Fragment>
          ))}
        </SideContent>
      );
    }

    case 'DESKTOP_SIDE_ADVERT': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        <Ad
          {...block.props}
          {...props}
          isSkippable
        />
      );
    }

    case 'DESKTOP_INLINE_ADVERT':
    case 'MOBILE_INLINE_ADVERT': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      const platform = type === 'DESKTOP_INLINE_ADVERT' ? 'desktop' : 'mobile';

      return (
        <Ad
          {...block.props}
          {...props}
          extraClassNames={{
            ...block.props.extraClassNames,
            'hide-desktop': platform === 'mobile',
            'hide-tablet': platform === 'desktop',
            'hide-mobile': platform === 'desktop',
          }}
          skippable={platform === 'desktop'}
          isSpaceReserved
          targetViewport={platform}
        />
      );
    }

    case 'TEADS': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        <Ad
          {...block.props}
          {...props}
          isTeads
        />
      );
    }

    case 'DIGITEKA': {
      if (layoutType === 'commercialPage') return null;
      if (layoutType === 'commercialPageTitleImage') return null;

      return (
        getDigiteka({
          ...block.props,
          ...props,
          zoneAmp: 7,
        })
      );
    }

    case 'ItemBlock': {
      switch (block.itemType) {
        case 'GALLERY_SLIDESHOW':
        case 'GALLERY_LINKED': {
          const media = block.item.images.filter(i => i.__typename !== 'LinkedMedia');
          return (
            <Gallery
              mode={block.itemType === 'GALLERY_SLIDESHOW' ? 'swipe' : 'linkout'}
              cover={block.item.teaserImage}
              url={block.item.url}
              media={media}
              viewAllLabel="View Gallery"
              viewAllUrl={block.item.url}
              {...props}
            />
          );
        }
        default: return null;
      }
    }

    case 'REVIEW': {
      const { rating, header, subtitle } = data;
      return getReviewList(
        header,
        subtitle,
        parseFloat(rating),
        { '-inline': true },
      );
    }

    case 'ValuationsBlock': {
      const valuationtoolConfig = options?.props?.context?.valuationtoolConfig;

      return (
        <ValuationToolWidget
          {...props}
          {...valuationtoolConfig}
        />
      );
    }

    case 'PlaceholderBlock': {
      const blockData = JSON.parse(block.data);
      const valuationtoolConfig = options?.props?.context?.valuationtoolConfig;

      if (block.placeholder) {
        switch (block.placeholder) {
          case 'FAQsAccordion':
            return (
              <AccordionFAQs
                key={`accordionFAQsWidget_${index}`}
                data={blockData}
                iconClose="polaris__ico-arrow_up"
                iconOpen="polaris__ico-arrow_down"
              />
            );
          case 'VALUATION_TOOL_WIDGET':
            return (
              <ValuationToolWidget
                {...props}
                {...valuationtoolConfig}
              />
            );
          default:
            return false;
        }
      }

      break;
    }

    default:
      return false;
  }
};
