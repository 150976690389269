import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { compose } from 'recompose';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getNativeAd } from 'SharedPartials/getNativeAd';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getRelatedContent,
  getSimilarProductContent,
} from 'Helpers';

import {
  getArticleGroup,
  getBreadcrumbs,
  getNextSteps,
  getPostMeta,
  getReportCard,
  getReviewMultipageNavigation,
  getSocialSharingButtons,
  getTabs,
  getTags,
} from 'SharedPartialsLocal';

// Template styles and imports
import 'Styles/components/templates/_Review.scss';

const OwnerReview = (props) => {
  const {
    layoutData: {
      page: {
        associatedContent,
        breadcrumbs,
        contentType,
        isSponsored,
        kicker,
        ownerReviewData,
        tags,
        title,
        url,
      },
    },
    dataLayer,
    amp,
    config: {
      siteUrl,
      social: {
        share: {
          platforms: socialSharingPlatforms,
        },
      },
    },
  } = props;

  const { layoutData: { page } } = props;
  const brochureLink = page && get(page, 'vehicleNextSteps.brochure', '');
  const testDriveLink = page && get(page, 'vehicleNextSteps.testDrive', '');
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__content">

            { getBreadcrumbs(breadcrumbs, isSponsored, kicker) }

            <Heading size={1} extraClassNames={{ '-content-title': true }}>
              {title}
            </Heading>

            {getPostMeta(page, false, siteUrl, dataLayer)}

          </div>

          <div className="polaris__content">
            {getReviewMultipageNavigation(multipage, url)}
            {getReportCard(props)}
          </div>

          <div className="polaris__content polaris__simple-grid -body-copy">
            <div className="polaris__simple-grid--main">
              {ownerReviewData && getTabs(ownerReviewData)}
              {getNextSteps(brochureLink, testDriveLink)}
              <div className="polaris__content-bottom">
                {getTags(tags, false)}
                {getSocialSharingButtons({
                  title,
                  siteUrl,
                  url,
                  socialSharingPlatforms,
                  extraClassNames: { 'polaris__review--social': true },
                })}
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="polaris__content">
        {getArticleGroup({
          title: 'Have you considered?',
          content: getSimilarProductContent(associatedContent),
          extraClassNames: { 'polaris__review--consider': true },
          articleCardProps: { datePosition: 'meta', showRating: true, excerpt: null },
        })}
        {getArticleGroup({
          title: 'Most Popular',
          content: getArticleGroupContent(associatedContent, 'mostPopular', {
            component: [
              <React.Fragment key="native_article">
                {getNativeAd({
                  id: 'native_article',
                  targeting: {
                    position: 'native_article',
                    placement: 'native_article',
                    refresh: 'no',
                  },
                })}
              </React.Fragment>,
            ],
          }),
          extraClassNames: { 'polaris__review--most-popular': true },
          articleCardProps: {
            isHeading: false,
            showRating: true,
          },
        })}
      </div>
    </>
  );
};

OwnerReview.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  amp: PropTypes.bool.isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    newsletterConfig: PropTypes.shape(),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.shape(),
      }),
    }),
    header: PropTypes.shape({
      logoImage: PropTypes.string,
    }),
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

OwnerReview.defaultProps = {
  dataLayer: {},
  location: {
    search: '',
    pathname: '',
  },
};

const MemoOwnerReview = React.memo(OwnerReview);

export default compose(
  withTemplate,
  withHelmet('review'),
)(MemoOwnerReview);
