import React from 'react';
import { DisqusCount } from '@autovia-uk/polaris-components/components/molecules/DisqusCount';
import { PostMeta } from '@autovia-uk/polaris-components/components/molecules/PostMeta';
import { getAuthors } from '@autovia-uk/polaris-components/sharedHelpers/getAuthors';
import 'Styles/components/molecules/_PostMeta.scss';

export const getPostMeta = (
  page,
  enableComments,
  siteUrl,
  dataLayer,
  disqusShortname,
  brandConfig,
) => {
  const {
    date,
    categories,
    tags,
    url,
    title,
    contentType,
    layoutType,
  } = page;

  let { authors } = page;

  const {
    nodeId,
  } = dataLayer || {};

  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';

  // Do not show authors for OWNER_REVIEW content type and commercial pages
  if (contentType === 'OWNER_REVIEW' || isCommercialPage || isCommercialPageTitleImage) {
    authors = false;
  }

  const authorsList = getAuthors({ authors, brandConfig });
  const filteredAuthors = Array.isArray(authorsList) ? authorsList.map((author) => {
    const { name, url: originalAuthorUrl, image } = author;
    return {
      author: name,
      authorUrl: originalAuthorUrl,
      authorImage: { src: image?.src },
    };
  }) : [];

  const postMetaAuthor = Array.isArray(filteredAuthors) && filteredAuthors.length === 1 ? filteredAuthors[0] : { authors: filteredAuthors };

  return (
    <div className="polaris__simple-grid">
      <div className="polaris__simple-grid--main">
        <PostMeta
          date={date}
          categories={categories}
          tags={tags}
          {...postMetaAuthor}
          extraComponent={
            enableComments
            && disqusShortname
            && !isCommercialPage
            && !isCommercialPageTitleImage
            && (
              <DisqusCount
                identifier={`node/${nodeId}`}
                url={`${siteUrl}${url}`}
                title={title}
                shortname={disqusShortname}
                commentsContainer="#polaris_comments"
              />
            )
          }
        />
      </div>
    </div>
  );
};
