import React from 'react';
import PropTypes from 'prop-types';

import { Helmet, contentBlocksToText } from 'polaris-coreweb/exports';
import {
  getPaginatedTitle,
} from 'Helpers';
import { getPrimaryMediaPreloadLink } from 'SharedPartialsLocal';

const getHelmetForIndexTemplate = (props) => {
  const {
    context: {
      imageSizesCallback,
    },
    layoutData: {
      page,
      page: {
        teaser,
      },
    },
    metaData: {
      pageTitle: metaTitle,
      pageDescription: metaDescription,
    },
    pagination: {
      page: currentPage,
    },
  } = props;

  const description = contentBlocksToText(teaser) || metaDescription;

  return (
    <Helmet>
      <title>{getPaginatedTitle(metaTitle, currentPage)}</title>
      {description && (
        <script type="application/ld+json">
          {`{
            "description": "${description}"
          }`}
        </script>
      )}
      <meta property="twitter:description" content={description} />
      <meta property="og:description" content={description} />

      {getPrimaryMediaPreloadLink(page, imageSizesCallback)}
    </Helmet>
  );
};

getHelmetForIndexTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      teaser: PropTypes.array,
    }),
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
};

export default getHelmetForIndexTemplate;
