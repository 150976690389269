import React from 'react';
import { AuthorBio } from '@autovia-uk/polaris-components/components/molecules/AuthorBio';
import { HtmlSafe } from 'polaris-coreweb/exports';

export const getAuthorBio = author => (
  <AuthorBio
    name={author.name || ''}
    photo={
      {
        src: author.photo ? author.photo.src : '',
        alt: (author.photo && author.photo.alt) ? author.photo.alt : '',
      }
    }
    bio={<HtmlSafe html={author.bio || ''} />}
    socialPlatforms={author.social ? author.social.map(item => ({ ...item, label: `Follow us on ${item.platform}` })) : null}
  />
);
