import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getBody } from 'SharedPartials/getBody';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getRightSideAdConfig,
} from 'Helpers';

import {
  getArticleGroup,
  getBreadcrumbs,
  getNewsletter,
  getPromoBoxSettings,
  getSubscription,
} from 'SharedPartialsLocal';

const BasicPage = ({
  layoutData: {
    page: {
      layoutType,
      associatedContent,
      body,
      breadcrumbs,
      kicker,
      subtitle,
      title,
    },
  },
  config: {
    breakpoints,
    globalSettings: {
      promoBoxSettings,
    },
    newsletterConfig,
  },
}) => {
  const isLandingPage = layoutType === 'landingPage';

  let extraClasses = [];
  const bodyExtended = body.map((m) => {
    if (
      (m.__typename && m.__typename === 'ValuationsBlock')
      || (m.__typename && m.__typename === 'PlaceholderBlock' && m.placeholder && m.placeholder === 'VALUATION_TOOL_WIDGET')) {
      extraClasses = [...extraClasses, '-has-embed__valuationsblock'];
    }

    return { ...m };
  });

  // remove duplicates, and convert array in space separated string
  extraClasses = [...new Set(extraClasses)].join(' ');

  const bottomComponents = !isLandingPage && [
    getSubscription(getPromoBoxSettings({
      promoBoxSettings,
      ppc: promoBoxSettings.subscriptionArticle,
      mainTitle: null,
    })),
    getNewsletter({ newsletterConfig }),
  ];

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  return (
    <>
      <div className={`polaris__body ${extraClasses}`}>
        <main className="polaris__main" id="main">
          {!isLandingPage && (
            <div className="polaris__core-content polaris__content polaris__heading-block -with-bottom-border">
              <div className="polaris__heading-block--inner">
                {getBreadcrumbs(breadcrumbs, false, kicker, title)}
              </div>
            </div>
          )}

          <div className={`polaris__core-content polaris__core-content-basic-page polaris__content ${isLandingPage ? '-body-full-width' : 'polaris__simple-grid'} -body-copy`}>
            <div className="polaris__simple-grid--main">

              <div className="polaris__text--container">
                {getBody({
                  bodyInjected: bodyExtended,
                  template: 'page',
                  topComponents: !isLandingPage && subtitle && (
                    <Heading size={2} extraClassNames={{ 'polaris__article--subtitle': true }}>
                      {subtitle}
                    </Heading>
                  ),
                  bottomComponents,
                  // if layoutType is undefined, set it to 'Full width'
                  // this is because we don't have ad injection when on the basic pages,
                  // so there is no need to split the body block to two columns
                  layoutType: !layoutType ? 'Full width' : layoutType,
                  isMobile,
                })}
              </div>
            </div>

            {!isLandingPage && (
              <div className="polaris__ad-sidebar polaris__simple-grid--aside">
                <Ad {...getRightSideAdConfig()} />
              </div>
            )}
          </div>

          {!isLandingPage && (
            <div className="polaris__core-content polaris__content polaris__related-content">
              {getArticleGroup({
                title: 'Most Popular',
                content: getArticleGroupContent(associatedContent, 'mostPopular', {
                  component: [
                    <React.Fragment key="native_article">
                      {getNativeAd({
                        id: 'native_article',
                        targeting: {
                          position: 'native_article',
                          placement: 'native_article',
                          refresh: 'no',
                        },
                      })}
                    </React.Fragment>,
                  ],
                }),
                extraClassNames: { 'polaris__article--most-popular': true },
                articleCardProps: {
                  isHeading: false,
                  showRating: true,
                },
              })}
            </div>
          )}
        </main>
      </div>
    </>
  );
};

BasicPage.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    globalSettings: PropTypes.shape({
      promoBoxSettings: PropTypes.object.isRequired,
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
  }).isRequired,
};

export default compose(
  withTemplate,
  withHelmet('page'),
)(BasicPage);
