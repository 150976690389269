import get from 'lodash.get';
import { Helmet, imageSizesCallback } from 'polaris-coreweb/exports';
import { getHeaderConfig } from './getHeaderConfig';
import { getFooterConfig } from './getFooterConfig';

// further info in withTemplate.md
export const contextOverride = (config, adConfig, amp) => ({
  header: getHeaderConfig(config),
  branding: get(config, 'branding', {}),
  breakpoints: get(config, 'breakpoints', {}),
  globalSettings: get(config, 'globalSettings', {}),
  footer: getFooterConfig(config),
  social: get(config, 'social', {}),
  testing: true,
  imageSizesCallback: imageSizesCallback(config),
  platform: amp ? 'amp' : 'canonical',
  promoBoxSettings: get(config, 'globalSettings.promoBoxSettings', []),
  HelmetComponent: Helmet,
  adConfig,
  ratingFillColor: get(config, 'rating.color.ratingFillColor', {}),
  ratingFillInactiveColor: get(config, 'rating.color.ratingFillInactiveColor', {}),
  newsletter: get(config, 'newsletterConfig', {}),
  newsletterURL: get(config, 'newsletter.newsletterURL', null),
  valuationtoolConfig: get(config, 'valuationtoolConfig', {}),
  svgSpritePath: get(config, 'svgSpritePath', '/assets/polaris-sprite.svg'),
});
