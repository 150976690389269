/* eslint-disable react/prop-types */
import React from 'react';
import uniqid from 'uniqid';

import { getTaxonomyFilter } from 'SharedPartialsLocal';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';

export const getIndexArticlePageHeader = ({
  indexData,
  propsData,
  showFilters = false,
  taxonomyFilterParams,
}) => {
  const {
    pagination: {
      pages,
      page: currPage,
    },
  } = propsData;

  if (!Array.isArray(pages[currPage]) || !pages[currPage].length) {
    return false;
  }

  return (
    <React.Fragment key={uniqid('article-index-')}>
      <div className="polaris__article-group--header">
        <div className="polaris__article-group--block">
          <Heading
            size={4}
            extraClassNames={{
              'polaris__article-group--title': true,
              '-more-on-title': true,
            }}
            key={uniqid('header-')}
          >
            {indexData.title ? `More on ${indexData.title}` : 'More like this'}
          </Heading>
        </div>
      </div>
      {showFilters && (
        <div className="polaris__gray--bg">
          {getTaxonomyFilter(taxonomyFilterParams)}
        </div>
      )}
    </React.Fragment>
  );
};
