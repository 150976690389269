import React from 'react';
import uniqid from 'uniqid';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { ArticleGroup } from '@autovia-uk/polaris-components/components/organisms/ArticleGroup';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { getNativeAd } from 'SharedPartials/getNativeAd';

import {
  getArticleContent,
  getNewsletter,
  getSubscription,
} from 'SharedPartialsLocal';

export const getHomeArticleGroup = (contentData) => {
  if (contentData === null || typeof contentData === 'undefined') {
    return null;
  }

  const {
    layoutType,
    type,
    content,
    url,
    subtitle,
    clientLogos,
  } = contentData;

  let {
    title,
  } = contentData;

  const contentItems = content.map(getArticleContent);

  let articleCardStyle = 'default';
  let articleCardProps = {
    // excerpt: null,
    // datePosition: 'bottom',
    showRating: true,
    // ratingPosition: 'after-title',
  };
  let extraClassNames = {};
  let articlesPerRow = 4;
  let showIndex = false;
  let hideImageMinimalIndex = 4;

  switch (layoutType) {
    case 'Hero Feature':
      articleCardStyle = 'featured';
      articlesPerRow = 3;
      title = null;
      articleCardProps = {
        showRating: true,
      };
      if (contentItems[0]) {
        contentItems[0].image.lazyLoad = false;
      }

      break;
    case 'Double List':
      articlesPerRow = 2;
      articleCardStyle = 'side';
      articleCardProps = {
        showRating: true,
      };
      break;
    case 'Row':
    case 'Hero List':
    case 'Feature and Short List':
      extraClassNames = {
        '-home-bottom-articles': true,
      };
      break;
    default:
      break;
  }

  switch (type) {
    case 'mostPopular':
      title = 'Most Popular';
      showIndex = false;
      extraClassNames['polaris__home--popular'] = true;
      articleCardStyle = 'minimal';
      hideImageMinimalIndex = 4;
      articleCardProps = {
        excerpt: null,
      };
      break;
    default:
      break;
  }

  let image = {};
  if (clientLogos && clientLogos.length && Array.isArray(clientLogos) && clientLogos[0].src) {
    image = {
      src: clientLogos[0].src,
      alt: clientLogos[0].alt || '',
    };
    extraClassNames['-is-sponsored'] = true;
  }

  return (
    <ArticleGroup
      key={uniqid()}
      title={title || ''}
      articlesPerRow={articlesPerRow}
      articleCardStyle={articleCardStyle}
      content={contentItems}
      articleCardProps={articleCardProps}
      extraClassNames={extraClassNames}
      showIndex={showIndex}
      hideImageMinimalIndex={hideImageMinimalIndex}
      ctaUrl={url}
      ctaLabel={subtitle}
      image={image}
    />
  );
};

export const getHomeContentBlocks = (associatedContent, config) => {
  const {
    promoBoxConfig,
    newsletterConfig,
  } = config;

  if (!associatedContent || !associatedContent.length
    || !Array.isArray(associatedContent)) {
    return false;
  }

  const components = [];
  let adIndex = 1;
  for (let i = 0; i < associatedContent.length; i += 1) {
    const { layoutType, type } = associatedContent[i];

    if (layoutType === 'Hero Feature') {
      // if first block in the homepage blocks is advert, assign the class to the first content block
      const topBlockClass = (i === 1 && adIndex === 2) ? ' -homepageTopContentBlock' : '';

      components.push((
        <div className={`polaris__content--featured${topBlockClass}`}>
          <div className="polaris__core-content polaris__content">
            {getHomeArticleGroup(associatedContent[i])}
          </div>
        </div>
      ));
    } else if (layoutType === 'Double List') {
      components.push((
        <div className="polaris__core-content polaris__content">
          {getHomeArticleGroup(associatedContent[i])}
        </div>
      ));
    } else if (type === 'adBlock') {
      // if first block in the homepage blocks is advert, assign the class used for the below_header ad
      const topAdClass = i === 0 ? ' polaris__below-header-ad-wrapper' : '';

      components.push((
        <div
          className={`polaris__content -homepageAdBlock -homepageAdBlock-${adIndex}${topAdClass}`}
          key={`adBlock-${adIndex}`}
        >
          <Ad
            id={`refresh-inline_${adIndex}`}
            isSkippable
            isSpaceReserved
            targeting={{
              position: `inline_${adIndex}`,
              placement: `inline_${adIndex}`,
              refresh: 'yes',
            }}
            type="slot-1"
          />
        </div>
      ));
      adIndex += 1;
    } else if (type === 'newsLetter') {
      components.push((
        <div className="polaris__core-content polaris__content">
          <div className="polaris__grid -with-columns-2">
            {promoBoxConfig && getSubscription(promoBoxConfig)}
            {getNewsletter({
              props: associatedContent[i],
              newsletterConfig,
            })}
          </div>
        </div>
      ));
    } else if (type === 'mostPopular') {
      const nativeAd = {
        component: [
          <React.Fragment key="native_home">
            {
              getNativeAd({
                id: 'native_home',
                targeting: {
                  position: 'native_home',
                  placement: 'native_home',
                  refresh: 'no',
                },
              })}
          </React.Fragment>,
        ],
      };
      const injectElement = (obj, index, item) => {
        const content = [...obj.content].slice(0, 7);
        content.splice(index, 0, item);
        return content;
      };
      const associatedContentWithNativeAd = { ...associatedContent[i], content: injectElement(associatedContent[i], 3, nativeAd) };

      components.push((
        <div className="polaris__core-content polaris__content -has-native-ad">
          {getHomeArticleGroup(associatedContentWithNativeAd)}
        </div>
      ));
    } else if (type === 'widgetBlock') {
      const { html } = associatedContent[i];

      if (html) {
        components.push((
          <HtmlSafe html={html} />
        ));
      }
    } else if (associatedContent[i].content && associatedContent[i].content.length > 0) {
      components.push((
        <div className="polaris__core-content polaris__content">
          {getHomeArticleGroup(associatedContent[i])}
        </div>
      ));
    }
  }

  return components;
};
