import React from 'react';
import { Collapsible } from '@autovia-uk/polaris-components/components/molecules/Collapsible';
import { Disqus } from '@autovia-uk/polaris-components/components/atoms/Disqus';
import { DisqusCount } from '@autovia-uk/polaris-components/components/molecules/DisqusCount';

export const getDisqusCollapsible = (siteUrl, url, title, nodeId, disqusShortname) => (
  <div className="polaris__comments" id="polaris_comments">
    <Collapsible
      title=""
      isOpen={false}
      iconOpen="polaris__ico-down"
      iconClose="polaris__ico-up"
      titleComponent={(
        <DisqusCount
          identifier={`node/${nodeId}`}
          url={`${siteUrl}${url}`}
          title={title}
          shortname={disqusShortname}
        />
      )}
      content={(
        <Disqus
          identifier={`node/${nodeId}`}
          url={`${siteUrl}${url}`}
          title={title}
          shortname={disqusShortname}
        />
      )}
    />
  </div>
);
