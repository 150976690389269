/**
 * @render react
 * @name Footer
 * @example
 * <Footer />
 * Example when mobile sticky ad should not be rendered on the page:
 * <Footer mobileStickyAd={false} />
 */

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withConfig } from 'polaris-coreweb/exports';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation';
import { Social } from '@autovia-uk/polaris-components/components/molecules/Social';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import {
  getPromoBoxSettings,
  getSubscription,
} from 'SharedPartialsLocal';
import './_style.scss';
// HELPERS
// In CMS new lines added via special symbols \r\n
// swap them for line break tag
// simple swap '\r\n' -> '<br />' in string doesn't give wanted solution, so making react components is a must
// https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
const renderCMSText = (str) => {
  if (typeof str !== 'string') return '';

  const newLine = '\r\n';
  if (!str.includes(newLine)) return str;

  return (
    <>
      {
        str.split(newLine).map((line, lineIndex) => (
          <React.Fragment key={`line_${lineIndex}`}>
            {line}
            <br />
          </React.Fragment>
        ))
      }
    </>
  );
};

const getPrivacyPreferenceLink = ({ privacyManagerId }) => ({
  title: 'Privacy Preferences',
  url: '#',
  __typename: 'MenuItem',
  onClick: (e) => {
    e.preventDefault();
    if (typeof window._sp_ === 'undefined'
    || typeof window._sp_.loadPrivacyManagerModal === 'undefined') {
      return;
    }
    window._sp_.loadPrivacyManagerModal(privacyManagerId);
  },
});

/**
 * Footer component
 *
 * @param {array} config - Array containing objects, defined in config file
 *        @param {string} logo.url - Path to image/icon which represents brand logo
 *        @param {string} logo.logoImage.src - Absolute URL to image/icon which represents brand logo
 *        @param {array} navigation.links
 *                  @param {string} navigation.links.url - URL which should lead to the section/page/external link. In case extarnal link or in case absolute URL, icon will be added to indicate it
 *                  @param {string} navigation.links.title - Title of the link in navigation
 *        @param {array} social.follow.platforms
 *                  @param {string} social.follow.platforms.label - Label to be used in case text is display
 *                  @param {string} social.follow.platforms.url - URL to the site social platform profiles/pages
 *                  @param {string} social.follow.platforms.platform - Type of the social platform (ex Facebook, Twitter, Instagram, etc)
 * @param {Object} context - Extra information coming from Polaris provider.
 * @param {bool} mobileStickyAd - Show/Hide Ad Component (mobile sticky ad). Ad is displayed by default. Default: true
 * @param {Object} promoBoxSettings - - Object that contains information for the subscription box.
 */
const Footer = (props) => {
  const {
    context: {
      cssPrefix,
      footer: {
        navigation,
      },
      globalSettings: {
        copyright,
        promoBoxSettings,
        sourcepoint,
      },
      social,
    },
    layoutType,
    mobileStickyAd,
  } = props;

  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';
  const isLandingPage = layoutType === 'landingPage';

  const footerNavigation = navigation ? [...navigation] : [];
  if (sourcepoint && sourcepoint.privacyManagerId) {
    footerNavigation.push(getPrivacyPreferenceLink(sourcepoint));
  }

  return (
    <>
      <div className="screen-reader-text">
        <a href="#header">Skip to Header</a>
        <a href="#main">Skip to Content</a>
      </div>
      <footer
        className={classNames({
          [`${cssPrefix}__footer`]: true,
          [`${cssPrefix}__footer-landing`]: isLandingPage,
        })}
        id="footer"
      >
        <div className={`${cssPrefix}__footer--inner`}>
          <div className={`${cssPrefix}__footer--row`}>
            <div className={`${cssPrefix}__content -footer-nav`}>

              <div className={`${cssPrefix}__footer--navigation`}>
                {navigation && (
                  <Navigation
                    content={footerNavigation}
                    amp={false}
                  />
                )}
              </div>

              {!isLandingPage && promoBoxSettings && (
                <div className={`${cssPrefix}__footer--subscription`}>
                  {getSubscription(
                    getPromoBoxSettings({
                      promoBoxSettings,
                      ppc: promoBoxSettings.subscriptionFooter,
                      excerpt: promoBoxSettings.shortText,
                      image: null,
                      extraClassNames: { [`${cssPrefix}__subscribe-to-magazine`]: true },
                    }),
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={`${cssPrefix}__footer--row`}>
            <div className={`${cssPrefix}__content`}>
              <div className={`${cssPrefix}__footer--copyright`}>
                <p>{renderCMSText(copyright)}</p>
              </div>

              {!isLandingPage && (
                <Social
                  ampEnable={false}
                  platforms={social.follow.platforms}
                  extraClassNames={{ [`${cssPrefix}__footer--social`]: true }}
                />
              )}
            </div>
          </div>
        </div>

        {!isCommercialPage
        && !isCommercialPageTitleImage
        && !isLandingPage
        && mobileStickyAd
        && (
          <Ad
            id="refresh-stickyfooter"
            type="stickyfooter"
            renderWhenViewable={false}
            targeting={{
              position: 'stickyfooter',
              placement: 'stickyfooter',
              refresh: 'yes',
            }}
            isMobileSticky
          />
        )}
      </footer>
    </>
  );
};

Footer.propTypes = {
  context: PropTypes.shape({
    footer: PropTypes.shape({
      logo: PropTypes.shape({
        src: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string,
        alt: PropTypes.string,
      }).isRequired,
      navigation: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      })),
    }),
    social: PropTypes.shape({
      follow: PropTypes.shape(),
    }),
    globalSettings: PropTypes.shape({
      copyright: PropTypes.string,
      promoBoxSettings: PropTypes.object,
      sourcepoint: PropTypes.object,
    }),
    cssPrefix: PropTypes.string,
  }).isRequired,
  layoutType: PropTypes.string,
  mobileStickyAd: PropTypes.bool,
};

Footer.defaultProps = {
  layoutType: '',
  mobileStickyAd: true,
};

export default compose(withConfig, withPolaris)(Footer);
