import React from 'react';
import { Slider } from '@autovia-uk/polaris-components/components/organisms/Slider';
import { NextPages } from '@autovia-uk/polaris-components/components/molecules/NextPages';
import 'Styles/components/molecules/_MultiPageNavigation.scss';

export const getReviewMultipageNavigation = (
  multipage = false,
  currentUrl = null,
) => {
  if (!multipage || (Array.isArray(multipage) && multipage.length === 0)) {
    return false;
  }

  return (
    <div className="polaris__subnav">
      <Slider
        settings={{
          container: '.polaris__subnav .polaris__next-pages--list',
          items: 2,
          startIndex: 0,
          nav: false,
          controlsContainer: '.polaris__subnav .polaris__slider--controls',
          controlsPosition: 'bottom',
          loop: false,
          responsive: {
            375: {
              items: 2,
            },
            768: {
              items: 3,
            },
            1024: {
              items: 4,
            },
            1260: {
              disable: true,
            },
          },
        }}
        nextIcon="polaris__ico-next"
        prevIcon="polaris__ico-prev"
        stickyEnabled
        stickyTop={0}
        stickyInnerZ={9999}
      >
        <NextPages
          currentPageUrl={currentUrl}
          content={multipage.map(el => ({
            url: el.url,
            title: el.title,
          }))}
          showIndex
          loadStyles={false}
          extraClassNames={{
            'polaris__subnav--inner': true,
          }}
        />
      </Slider>
    </div>
  );
};
