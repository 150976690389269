import React from 'react';
import { compose } from 'recompose';

import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';

import {
  withTemplate,
  withHelmet,
} from 'Protons';

// Template styles and imports
import 'Styles/components/templates/_Error.scss';

const ErrorPage = () => (
  <>
    <div className="polaris__body -error-template">
      <main className="polaris__main" id="main">
        <div className="polaris__content">
          <div className="polaris__core-content polaris__content -center -body-copy">
            <Heading size={2}>
              Site Error
            </Heading>

            <p>It appears there is an error with the site. Apologies!</p>
            <p>Our team has been notified. Please reload and try again.</p>
          </div>
        </div>
      </main>
    </div>
  </>
);

export default compose(
  withTemplate,
  withHelmet('error'),
)(ErrorPage);
