import get from 'lodash.get';

/**
 * Function to convert menus from format in middleware to component library version.
 *
 * Menus in middleware are an array of menus with a "type" (e.g. footer, header), and either reference an
 * external URL or a page entity instead of explicitly declaring the target attribute.
 * @param {Object}   The configuration object from the middleware.
 * @returns {Object} Converted navigation object.
 */
export const getNavigationConfig = (config) => {
  if (!config) {
    return {};
  }
  const getMenu = get(config, 'globalSettings.getMenu');
  if (!getMenu) {
    return {};
  }
  const navigation = {};
  getMenu.forEach(({ type, items }) => {
    navigation[type] = items.map((menuItem) => {
      const { item: entity, ...item } = menuItem;
      if (entity && entity.url) {
        return {
          ...item,
          url: entity.url,
        };
      }
      const rel = (type === 'header') ? 'noopener noreferrer nofollow' : null;

      return { ...item, target: '_blank', rel };
    });
  });
  return navigation;
};
