import { formatURLforPPC } from 'SharedHelpers/formatURLforPPC';

export const getPromoBoxSettings = ({
  promoBoxSettings,
  extraClassNames,
  ctaUrl,
  mainTitle,
  excerpt,
  image,
  ppc,
}) => {
  const clonedCtaUrl = (ctaUrl !== undefined && ctaUrl) || promoBoxSettings?.ctaUrl;
  const clonedImage = (image || image === null) ? image : promoBoxSettings?.image;

  return ({
    ...promoBoxSettings,
    extraClassNames,
    mainTitle: mainTitle || promoBoxSettings?.mainTitle,
    excerpt: excerpt || promoBoxSettings?.excerpt,
    image: clonedImage,
    ctaUrl: clonedCtaUrl && formatURLforPPC({
      url: clonedCtaUrl,
      ppc,
    }),
  });
};
