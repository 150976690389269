import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setDisplayName } from 'recompose';
import merge from 'deepmerge';
import get from 'lodash.get';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { withPagination } from 'polaris-coreweb/exports';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getIndex,
  getRelatedContent,
  getSimilarProductContent,
} from 'Helpers';

import { countBodyInjectedInlineAds } from 'SharedHelpers/countBodyInjectedInlineAds';
import { getAfterContentAd } from 'SharedPartials/getAfterContentAd';
import { getBody } from 'SharedPartials/getBody';
import { getIndexArticlePageContent } from 'SharedPartials/getIndexArticlePageContent';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getSponsorInfo } from 'SharedPartials/getSponsorInfo';

import {
  getArticleBottomComponents,
  getArticleGroup,
  getBreadcrumbs,
  getIndexArticlePageHeader,
  getInThisArticle,
  getNextSteps,
  getPagination,
  getPostMeta,
  getPrimaryMedia,
  getPromoBoxSettings,
  getReportCard,
  getReviewMultipageNavigation,
  getWhichIsBest,
} from 'SharedPartialsLocal';

import rules from '@autovia-uk/injection-default-rules-set';
import injectionEngine from '@autovia-uk/injection-engine';
import { ReviewQueryPagination as query } from './ReviewQuery.graphql';

import 'Styles/components/templates/_Review.scss';

const Review = (props) => {
  const {
    amp,
    config: {
      branding: {
        logoSquare: {
          src,
        },
      },
      breakpoints,
      globalSettings: {
        adSettings,
        adSettings: { afterContentDesktopAds, afterContentMobileAds },
        name,
        promoBoxSettings,
      },
      newsletterConfig,
      siteUrl,
      social: {
        share: {
          platforms: socialSharingPlatforms,
        },
      },
    },
    dataLayer,
    layoutData: {
      page: {
        associatedContent,
        body,
        breadcrumbs,
        filters,
        isSponsored,
        kicker,
        monetising,
        primaryMedia,
        showFilters,
        sideContent,
        specsPath,
        sponsor: sponsorInfo,
        subtitle,
        tags,
        title,
        url,
      },
      page,
    },
  } = props;

  const brandConfig = {
    name,
    logo: {
      src,
    },
  };

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;
  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const brochureLink = page && get(page, 'vehicleNextSteps.brochure', '');
  const testDriveLink = page && get(page, 'vehicleNextSteps.testDrive', '');
  const multipage = getRelatedContent(associatedContent, 'relatedPages');
  const indexData = getIndex(props);
  const isIndexArticlePage = indexData !== false;
  let bodyInjected = injectionEngine({
    body,
    rules,
    amp,
    props: {
      adSettings,
      breakpoints,
      monetising,
      newsletterConfig,
      pageContentType: 'review',
      promoBoxSettings: getPromoBoxSettings({
        promoBoxSettings,
        ppc: promoBoxSettings.subscriptionArticle,
      }),
      sideContent,
      amp,
    },
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const vehicleBests = page && get(page, 'vehicleBests.vehicleWhichIsBestVerdict', null);
  const reportCard = getReportCard(props);
  const primaryMediaExtraClass = reportCard ? '-has-report-card -article' : '-article';

  const bodyTopComponents = (
    <div className="polaris__simple-grid--main">
      {getPrimaryMedia(primaryMedia, primaryMediaExtraClass)}
      {reportCard || null}
      {getSponsorInfo({ sponsor: sponsorInfo })}
    </div>
  );

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            { getBreadcrumbs(breadcrumbs, isSponsored, kicker) }
            <Heading size={1} extraClassNames={{ '-content-title': true }}>
              {title}
            </Heading>
            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>
            {getPostMeta(page, false, siteUrl, dataLayer, null, brandConfig)}
          </div>
          <div className="polaris__core-content polaris__content">
            {getReviewMultipageNavigation(multipage, url)}
          </div>
          <div className="polaris__content -body-copy">
            {getBody({
              bodyInjected,
              template: 'review',
              bottomComponents: getArticleBottomComponents({
                siteUrl,
                associatedContent,
                tags,
                title,
                url,
                socialSharingPlatforms,
              }),
              topComponents: bodyTopComponents,
              isMobile,
            })}
            <div className="polaris__core-content polaris__simple-grid--main">
              {getPagination(associatedContent, url)}
              {getNextSteps(brochureLink, testDriveLink)}
              {multipage && getWhichIsBest(
                vehicleBests,
                'Which Is Best',
                specsPath,
                {
                  '-no-bottom-border': true,
                  '-review': true,
                },
              )}
              {getInThisArticle('In this review', url, multipage)}
            </div>
          </div>
        </main>
      </div>
      <div className="polaris__content">
        {getArticleGroup({
          title: 'Recommended',
          content: getArticleGroupContent(associatedContent, 'recommended'),
          extraClassNames: { 'polaris__article--consider': true, 'polaris__core-content': true },
          articleCardProps: {
            headingSize: 4,
            showRating: true,
          },
        })}
        {getArticleGroup({
          title: 'Have you considered?',
          content: getSimilarProductContent(associatedContent),
          extraClassNames: { 'polaris__review--consider': true, 'polaris__core-content': true },
          articleCardProps: [{
            // datePosition: 'bottom',
            showRating: true,
            excerpt: null,
          }],
        })}
      </div>
      {(afterContentDesktopAds || afterContentMobileAds) && (getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds }))}
      <div className="polaris__content">
        {/* Inject the Native ad in the Most Popular block as the last item */}
        {!isIndexArticlePage
          && (
            <div className="polaris__core-content">
              {getArticleGroup({
                title: 'Most Popular',
                content: getArticleGroupContent(associatedContent, 'mostPopular', {
                  component: [
                    <React.Fragment key="native_article">
                      {getNativeAd({
                        id: 'native_article',
                        targeting: {
                          position: 'native_article',
                          placement: 'native_article',
                          refresh: 'no',
                        },
                      })}
                    </React.Fragment>,
                  ],
                }),
                extraClassNames: { 'polaris__review--most-popular': true },
                articleCardProps: {
                  isHeading: false,
                  showRating: true,
                },
              })}
            </div>
          )}
        {isIndexArticlePage && (
          <div>
            <div className="polaris__core-content polaris__more-on--container">
              {getIndexArticlePageHeader({
                indexData,
                propsData: props,
                showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
                taxonomyFilterParams,
              })}
            </div>
            {getIndexArticlePageContent(
              indexData,
              {
                ...props,
                articleCardProps: {
                  datePosition: 'bottom',
                  headingSize: 4,
                  showRating: true,
                },
                articlesPerRow: 4,
              },
              countInlineAds,
              isMobile,
            )}
          </div>
        )}
      </div>
    </>
  );
};

Review.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    page: PropTypes.number,
    pages: PropTypes.object,
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
    nextUrl: PropTypes.string,
  }).isRequired,
  amp: PropTypes.bool,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    branding: PropTypes.shape({
      logoSquare: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    globalSettings: PropTypes.shape({
      adSettings: PropTypes.shape({
        afterContentDesktopAds: PropTypes.bool,
        afterContentMobileAds: PropTypes.bool,
        listGalleryAds: PropTypes.number,
        minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
        minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
        minNumOfWordsBeforeTeads: PropTypes.number,
        numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
        numberOfWordsBetweenInlineMobileAds: PropTypes.number,
        numberOfWordsForFirstInjectionDesktop: PropTypes.number,
        numberOfWordsForFirstInjectionMobile: PropTypes.number,
        refreshBlacklist: PropTypes.string,
        refreshSiteWide: PropTypes.bool,
        refreshTime: PropTypes.number,
      }),
      monetising: PropTypes.arrayOf(
        PropTypes.shape({
          config: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
            }),
          ).isRequired,
          enabled: PropTypes.bool.isRequired,
          service: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      promoBoxSettings: PropTypes.shape(),
      name: PropTypes.string.isRequired,
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          platform: PropTypes.string,
        })),
      }),
    }),
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

Review.defaultProps = {
  dataLayer: {},
  location: {
    search: '',
    pathname: '',
  },
  amp: false,
};

const setPageNumber = compose(
  setDisplayName('setTotalPages()'), // Sets the display name for ease of debugging.
  withProps((props) => {
    const {
      layoutData: {
        page: {
          associatedContent,
        },
      },
      data,
    } = props;
    const index = associatedContent.find(({ type }) => type === 'indexArticlePageList');
    if (index && index.pagination && typeof index.pagination === 'object') {
      const { pagination: { totalPages } } = index;
      return { ...props, data: merge(data, { pagination: { totalPages } }) };
    }
    return {};
  }),
);

const MemoReview = React.memo(Review);

export default compose(
  setPageNumber,
  withPagination({
    query,
    path: ({ associatedContent }) => getRelatedContent(associatedContent, 'indexArticlePageList'),
  }),
  withTemplate,
  withPolaris,
  withHelmet('review'),
)(MemoReview);
