/* eslint-disable react/prop-types */
import React from 'react';

import get from 'lodash.get';

import { ReportCard } from '@autovia-uk/polaris-components/components/organisms/ReportCard';

import { HtmlSafe } from 'polaris-coreweb/exports';

import { getPrimaryMedia } from 'SharedPartialsLocal';

import {
  getRelatedContent,
} from 'Helpers';

import 'Styles/components/organisms/_ReportCard.scss';

export const getReportCard = (props) => {
  const {
    layoutData: { page: { primaryMedia } },
  } = props;

  const {
    layoutData: {
      page: {
        pageId,
        pros: rawPros,
        cons: rawCons,
        priceMin,
        priceMax,
        rating,
        product,
        associatedContent,
        specsPath,
        vehicleSpecList: {
          priceRange: {
            capPriceMin,
            capPriceMax,
          },
        },
      },
    },
  } = props;

  let priceOptions = {};
  let ratings = [];
  const reviewUrl = get(props, 'layoutData.page.ownerReview.url', null);
  const relatedContent = getRelatedContent(associatedContent, 'relatedPages');

  const min = capPriceMin || priceMin;
  const max = capPriceMax || priceMax;

  if (min !== 0 || max !== 0) {
    priceOptions = {
      min,
      max,
      minOnly: false,
      prefixText: 'from ',
      priceSeparator: 'to',
    };
  }

  let overAllRating = rating;
  let tabRating;
  const ratingsRounding = 1;
  if (relatedContent !== null && relatedContent.length > 0 && relatedContent[0].id !== pageId) {
    overAllRating = relatedContent[0].rating || 0;
    const currentSection = relatedContent.filter(item => item.id === pageId);
    if (currentSection && Array.isArray(currentSection) && currentSection.length) {
      tabRating = {
        showTextRating: false,
        value: rating,
        label: `${currentSection[0].title || 'This Section'} Rating`,
        rounding: ratingsRounding,
      };
    }
  }

  if (overAllRating > 0) {
    ratings = [
      {
        label: 'Evo rating',
        value: overAllRating,
        showTextRating: false,
        rounding: ratingsRounding,
      },
    ];
  }

  // Disable currently on EVO since the ratings are the same for every page
  // @TODO double check with management
  if (tabRating) {
    // ratings.push(tabRating);
  }

  const toList = (list) => {
    if (list.length > 0) {
      return `<ul>${list.map(item => `<li key={uniqid('report-list-item-')}>${item}</li>`).join('')}</ul>`;
    }

    return null;
  };
  const pros = Array.isArray(rawPros) ? toList(rawPros) : rawPros;
  const cons = Array.isArray(rawCons) ? toList(rawCons) : rawCons;
  const ctas = [];

  // ReportCard wont render if these are empty so neither will we
  if (!pros && !cons && ratings.length === 0 && Object.keys(priceOptions).length === 0) {
    return false;
  }

  if (reviewUrl !== null) {
    ctas.push({
      title: 'OWNER REVIEWS',
      url: reviewUrl,
    });
  }

  // Add SPEC link only if product exists - https://creativesolutions.atlassian.net/browse/APP-412
  // And add SPEC link from specsPath - https://creativesolutions.atlassian.net/browse/PAE-267
  if (product && specsPath) {
    ctas.push({
      title: 'SPECIFICATIONS',
      url: specsPath,
    });
  }

  return (
    <ReportCard
      // extraComponent={getPrimaryMedia(primaryMedia, '-report-card')}
      ratings={ratings}
      pros={pros && <HtmlSafe html={pros} />}
      cons={cons && <HtmlSafe html={cons} />}
      priceOptions={priceOptions}
      prosIcon="polaris__ico-plus"
      consIcon="polaris__ico-minus"
      /* eslint-disable-next-line */
      starsPath="M8.902.587l2.162 5.118 5.536.476c.384.033.54.512.249.764l-4.2 3.638 1.259 5.412a.436.436 0 0 1-.65.472L8.5 13.598l-4.757 2.87a.436.436 0 0 1-.65-.473l1.258-5.412-4.2-3.639A.436.436 0 0 1 .4 6.18l5.536-.476L8.098.587a.436.436 0 0 1 .804 0z"
      starsViewBox="0 0 17 17"
      starsDimensions="24px"
      starsSpacings="3px"
      ctas={ctas}
      ratingsRounding={ratingsRounding}
      showTextRating={false}
    />
  );
};
