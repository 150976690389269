import React from 'react';
import uniqid from 'uniqid';
import {
  getArticleGroup,
} from 'SharedPartialsLocal';
import {
  formatPageItemForWhitepaper,
} from 'Helpers';

export const getSearchPageItems = (resultPages, setPageRef, keywords, blankMessage) => (
  Object.entries(resultPages).map(([page, items]) => {
    // Creates an array, mapping data to what the props expect.
    const content = items.map((item) => {
      let searchPageItem = {
        ...item,
        title: item.altTitle || item.title,
        type: item.sponsor ? 'sponsored' : 'default',
      };

      searchPageItem = formatPageItemForWhitepaper(searchPageItem, item);
      if (searchPageItem.resourceCategory) {
        delete searchPageItem.resourceCategory;
      }

      return searchPageItem;
    });

    return (
      <div data-page={page} key={uniqid()} ref={setPageRef}>
        {getArticleGroup({
          title: '',
          content,
          extraClassNames: { '-side-layout': true },
          articlesPerRow: 1,
          articleCardProps: { datePosition: 'bottom', isHeading: false },
          articleCardStyle: 'side',
          blankMessage,
        })}
      </div>
    );
  }));
