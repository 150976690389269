/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PolarisApp, withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import {
  getMappedContentType,
} from 'Helpers';

import {
  Helmet,
} from 'polaris-coreweb/exports';

import { contextOverride } from './contextOverride';

/**
 * HOC to wrap templates to reduce boilerplate code.
 * @param {Component} Template The React component to wrap.
 */
const withMinimalTemplate = (Template) => {
  // Creates component with PolarisApp with correct image callback.
  const WrappedMinimalTemplate = (props) => {
    const {
      config = {},
      config: {
        fontsUrl,
      },
    } = props;

    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            href={fontsUrl ?? '/fonts/fonts.css'}
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
          />
          <noscript dangerouslySetInnerHTML={{
            __html: `
              <link
                rel="stylesheet"
                href={fontsUrl ?? '/fonts/fonts.css'}
              />
              `,
          }}
          />
        </Helmet>
        <PolarisApp
          config={{
            ...contextOverride(config),
          }}
        >
          <div className={`polaris__app polaris__${getMappedContentType(props)}--template`}>
            <Template {...props} />
          </div>
        </PolarisApp>
      </>
    );
  };
  WrappedMinimalTemplate.propTypes = {
    config: PropTypes.shape(),
  };

  WrappedMinimalTemplate.defaultProps = {
    config: {},
  };

  WrappedMinimalTemplate.displayName = 'withMinimalTemplate()';

  // Provides meta and config to template.
  return compose(withPolaris)(WrappedMinimalTemplate);
};

export default withMinimalTemplate;
