import React from 'react';
import 'Styles/components/molecules/_Breadcrumb.scss';
import { BreadcrumbWrapper } from '../atomic/protons/BreadcrumbWrapper';

export const getBreadcrumbs = (
  breadcrumbs = [],
  isSponsored = false,
  kicker = '',
  title = '',
) => (
  <BreadcrumbWrapper
    type={isSponsored ? 'sponsored' : 'default'}
    kicker={kicker}
    title={title}
    content={breadcrumbs}
  />
);
