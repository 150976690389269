import React from 'react';
import { NextPages } from '@autovia-uk/polaris-components/components/molecules/NextPages';
import 'Styles/components/molecules/_NextPages.scss';

export const getInThisArticle = (
  title = 'Continue Reading',
  currentPageUrl = null,
  multipage = null,
) => {
  if (multipage === null || (Array.isArray(multipage) && multipage.length === 0)) {
    return false;
  }

  return (
    <NextPages
      title={title}
      currentPageUrl={currentPageUrl}
      content={multipage.map(el => ({
        url: el.url,
        title: el.title,
        // rating: el.rating || false,
        rating: false,
        // subtitle: el.subtitle || false,
        subtitle: false,
      }))}
      showRating
    />
  );
};
