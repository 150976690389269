import React from 'react';
import { compose } from 'recompose';

import { Loading as LoadingDots } from '@autovia-uk/polaris-components/components/atoms/Loading';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

// Template styles and imports
import 'Styles/components/templates/_Loading.scss';

const Loading = () => (
  <>
    <div className="polaris__body">
      <main className="polaris__main" id="main">
        <div className="polaris__core-content polaris__content -center">
          <div className="polaris__loading-dots">
            <LoadingDots />
          </div>
        </div>
      </main>
    </div>

  </>
);

export default compose(
  withTemplate,
  withHelmet('loading'),
)(Loading);
