import get from 'lodash.get';

export const getMappedContentType = (props) => {
  const contentType = get(props, 'layoutData.page.contentType', '');

  switch (contentType) {
    case 'HOMEPAGE':
      return 'home';
    case 'AUTHOR_INDEX':
      return 'author';
    case 'BASIC_PAGE':
    case 'PAGE':
      return 'page';
    case 'REVIEW':
    case 'PAGINATED_REVIEW':
    case 'PAGINATED_REVIEW_VERDICT':
    case 'OWNER_REVIEW':
      return 'review';
    case 'SEARCH_INDEX':
      return 'search';
    case 'GALLERY':
      return 'gallery';
    case 'INDEX_PAGE':
    case 'TAXONOMY_INDEX':
      return 'index';
    case 'WHITEPAPER':
      return 'resource-download';
    case 'error':
      return 'error';
    case 'loading':
      return 'loading';
    case 'notFound':
      return 'not-found';
    case 'ARTICLE':
    case 'NEWS':
    case 'BESTS':
      return 'article';
    default:
      return 'article';
  }
};

export const getMappedLayoutType = (props) => {
  const layoutType = get(props, 'layoutData.page.layoutType', '');

  switch (layoutType) {
    case 'Full Width':
      return 'fullWidth';
    case 'Default':
      return 'default';
    case 'Landing Page':
    case 'landingPage':
      return 'landingPage';
    case 'Commercial Page':
    case 'commercialPage':
      return 'commercialPage';
    case 'Commercial Page Title Image':
    case 'commercialPageTitleImage':
      return 'commercialPageTitleImage';
    default:
      return 'default';
  }
};
