import React from 'react';

const getImageAttributes = (defaultSrc, imageSizesCallback, mediaSize) => {
  const attributes = {};

  if (!defaultSrc) {
    return attributes;
  }

  const { sizes, src, srcSet } = imageSizesCallback(defaultSrc, mediaSize);

  if (Array.isArray(sizes)) {
    attributes.imagesizes = sizes;
  }

  if (Array.isArray(srcSet)) {
    attributes.imagesrcset = srcSet;
  }

  attributes.src = src;

  return attributes;
};


const getPreloadImageLink = (inputSrc, imageSizesCallback, mediaSize) => {
  const { src, imagesizes, imagesrcset } = getImageAttributes(
    inputSrc,
    imageSizesCallback,
    mediaSize,
  );

  if (!src) {
    return null;
  }

  return (
    <link
      as="image"
      href={src}
      imageSizes={imagesizes ? imagesizes.join(', ') : ''}
      imageSrcSet={imagesrcset ? imagesrcset.join(', ') : ''}
      rel="preload"
    />
  );
};

const getPrimaryMediaSize = (
  contentType,
  mediaType = null,
  linkType = null,
) => {
  let mediaSize;

  switch (contentType) {
    case 'ARTICLE':
    case 'NEWS':
    case 'OWNER_REVIEW':
    case 'REVIEW': {
      if (mediaType === 'LinkedMedia') {
        switch (linkType) {
          case 'GALLERY_LINKED':
          case 'GALLERY_SLIDESHOW':
            mediaSize = 'content-image-full';
            break;
          default:
            mediaSize = 'content-image';
        }
      } else {
        mediaSize = 'content-image';
      }
      break;
    }

    case 'HOMEPAGE':
      mediaSize = 'hero';
      break;

    case 'GALLERY':
      mediaSize = 'content-image-full';
      break;

    case 'LIST_ARTICLE':
      mediaSize = 'content-image';
      break;

    case 'TAXONOMY_INDEX':
      mediaSize = 'card';
      break;

    default:
      mediaSize = 'content-image';
  }

  return mediaSize;
};

const getPrimaryMediaSrc = (page) => {
  const {
    associatedContent,
    contentType,
    galleryItems,
    images,
    primaryMedia,
  } = page;

  if (contentType === 'HOMEPAGE') {
    const heroBlock = Array.isArray(associatedContent) && associatedContent.find(
      ({ layoutType, type }) => layoutType === 'Hero Feature' || type === 'makemodelfinder',
    );
    return heroBlock?.content[0]?.image?.src || heroBlock?.image?.src;
  }

  if (contentType === 'GALLERY') {
    return (Array.isArray(images) && images[0]?.large)
      || (Array.isArray(galleryItems) && galleryItems[0]?.media[0]?.src);
  }

  if (contentType === 'TAXONOMY_INDEX') {
    return page?.items[0]?.image?.src;
  }

  if (!primaryMedia && contentType === 'LIST_ARTICLE') {
    return Array.isArray(galleryItems) && galleryItems[0]?.media[0]?.src;
  }

  switch (primaryMedia?.mediaType) {
    case 'Image': {
      return primaryMedia.src;
    }
    case 'LinkedMedia': {
      return primaryMedia?.page?.primaryMedia?.src;
    }
    default: {
      return null;
    }
  }
};

export const getPrimaryMediaPreloadLink = (page, imageSizesCallback) => {
  const { contentType } = page;

  const mediaSize = getPrimaryMediaSize(contentType, page.primaryMedia?.mediaType, page.primaryMedia?.linkType);

  const primaryMediaSrc = getPrimaryMediaSrc(page);

  return primaryMediaSrc
    ? getPreloadImageLink(
      primaryMediaSrc,
      imageSizesCallback,
      mediaSize,
    )
    : null;
};
