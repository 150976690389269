import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';

/**
 * Clean table from invalid attributes.
 * @param {*} html
 */
export const ampEnhanceTable = (children) => {
  const childrenProps = children;

  const updatedChildren = React.Children.map(childrenProps, (child) => {
    if (child.type === 'table' && child.props.xmlns) {
      const childProps = {
        xmlns: undefined,
      };
      return React.cloneElement(child, childProps);
    }

    if (typeof child.type === 'function' && child.props.html && child.props.html.indexOf('xmlns') > -1) {
      const regex = /xmlns=".+?"/gm;
      const htmlTable = child.props.html.replace(regex, '');
      const el = ReactHtmlParser(htmlTable);
      return el;
    }

    return child;
  });

  return updatedChildren;
};

/**
 * Renders the amp markup for the component.
 */
function renderAmp() {
  const {
    children,
    context: { cssPrefix },
    extraClassNames,
    loadStyles,
  } = this.props;

  return (
    <div
      className={
        classNames({
          [`${cssPrefix}__table`]: loadStyles,
          ...extraClassNames,
        })
      }
    >
      {children}
    </div>
  );
}

export default renderAmp;
