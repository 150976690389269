import React from 'react';
import { Tags } from '@autovia-uk/polaris-components/components/molecules/Tags';
import 'Styles/components/molecules/_Tags.scss';

export const getTags = (
  tags = null,
  checkTagsCondition = true,
) => {
  if (checkTagsCondition && (tags === null || (Array.isArray(tags) && tags.length === 0))) {
    return false;
  }

  return (
    <Tags content={tags} />
  );
};
