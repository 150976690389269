/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { compose } from 'recompose';
import { PolarisApp, withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { withMeta } from 'Protons/withMeta';
import { Header, Footer } from 'Organisms';
import classNames from 'classnames';

import {
  Helmet,
  useBlueConic,
  useCrimtan,
  usePermutive,
} from 'polaris-coreweb/exports';
import {
  getMappedContentType,
  getMappedLayoutType,
} from 'Helpers';

import { getOOPAds } from 'SharedPartials/getOOPAds';
import { getCustomizedAdConfig } from 'SharedHelpers/getCustomizedAdConfig';
import { pushWebVitalsToDataLayer } from 'SharedPartials/pushWebVitalsToDataLayer';
import { setPageTargeting } from '@autovia-uk/polaris-components/sharedHelpers/googlePublisherTag';
import { contextOverride } from './contextOverride';
import { getRtcConfig } from './getRtcConfig';


import { ProductSelectorQuery } from './ProductSelectorQuery.graphql';

/**
 * HOC to wrap templates to reduce boilerplate code.
 * @param {Component} Template The React component to wrap.
 */
const withTemplate = (Template) => {
  // Creates component with PolarisApp with correct image callback.
  const WrappedTemplate = (props) => {
    const {
      adConfig: pageConfig = {},
      amp,
      client,
      config = {},
      config: {
        globalSettings: {
          adSettings: {
            afterNavigationDesktopAds,
            afterNavigationMobileAds,
          },
          blueconicScriptUrl,
          disableBrowserRouter,
          gptTracking,
          headerBidding: {
            amazonHeaderBiddingEnabled,
            amazonPubId,
          },
          permutive: {
            projectId,
            publicApiKey,
            namespace,
          },
        },
        fontsUrl,
        branding: {
          logoSquarePng,
        },
      },
      dataLayer,
      layoutData,
      metaData,
    } = props;

    /**
     * Spread Operator madness! https://codeburst.io/use-es2015-object-rest-operator-to-omit-properties-38a3ecffe90
     *   combines pageConfig and config.ads into new adConfig object
     *   strips out (global) targeting from adConfig to be fired separately from individual ads
     *   moves targeting to new adConfig.globalTargeting object (in case needed again)
     */
    const { targeting, ...adConfig } = {
      ...pageConfig,
      templates: config.ads || {},
      rtcConfig: getRtcConfig(config),
      globalTargeting: pageConfig.targeting,
      indexExchangeAMP: config.indexExchangeAMP,
    };
    setPageTargeting(targeting);
    const mappedContentType = getMappedContentType(props);
    const contentTypeClass = ` polaris__${mappedContentType.toLowerCase()}--template`;
    const isHomeTemplate = mappedContentType.toLowerCase() === 'home';

    const { loading: makeModelDataLoading, data: makeModelData } = useQuery(ProductSelectorQuery, {
      ssr: false,
      client,
    });

    const layoutType = layoutData?.page?.layoutType;

    const mappedLayoutType = getMappedLayoutType(props);
    const isLandingPage = mappedLayoutType === 'landingPage';
    const isCommercialPage = mappedLayoutType === 'commercialPage';
    const isCommercialPageTitleImage = mappedLayoutType === 'commercialPageTitleImage';

    const BelowHeaderAd = () => {
      if (isLandingPage) return null;
      if (isHomeTemplate) return null;
      if (isCommercialPage) return null;
      if (isCommercialPageTitleImage) return null;

      return (
        <div
          className={classNames({
            'polaris__below-header-ad-wrapper': true,
            '-after-nav-mobile-ad': afterNavigationMobileAds,
            '-after-nav-desktop-ad': afterNavigationDesktopAds,
          })}
        >
          <Ad
            {...getCustomizedAdConfig({
              desktopRule: afterNavigationDesktopAds,
              mobileRule: afterNavigationMobileAds,
            })}
            extraClassNames={{
              '-full-width': true,
              '-below-header': true,
              'hide-mobile': !afterNavigationMobileAds,
              'hide-tablet': !afterNavigationMobileAds,
              'hide-desktop': !afterNavigationDesktopAds,
            }}
            id="refresh-below_header"
            isNoBackground
            isSkippable
            isSpaceReserved
            targeting={{
              position: 'below_header',
              placement: 'below_header',
              refresh: 'yes',
            }}
          />
        </div>
      );
    };


    useEffect(() => {
      if (!amazonHeaderBiddingEnabled || !amazonPubId) return;

      window.apstag.init({
        pubID: amazonPubId,
        adServer: 'googletag',
        simplerGPT: true,
      });
    }, []);

    if (gptTracking && gptTracking === true) {
      if (typeof window !== 'undefined') {
        import('gpt-tracker')
          .then((tracker) => {
            if (window.ga) {
              tracker.init();
            }
          });
      }
    }

    pushWebVitalsToDataLayer();
    useBlueConic(blueconicScriptUrl);
    useCrimtan();
    usePermutive(
      projectId,
      publicApiKey,
      dataLayer,
      namespace,
      config,
      amp,
    );

    let uniqueLinkKey = 0;
    const getUniqueLinkKey = () => {
      uniqueLinkKey += 1;
      return uniqueLinkKey;
    };

    return (
      <>
        <Helmet>
          {!amp && ([
            <link
              rel="preload"
              as="style"
              href={fontsUrl ?? '/fonts/fonts.css'}
              key={getUniqueLinkKey()}
            />,
            <link
              rel="stylesheet"
              href={fontsUrl ?? '/fonts/fonts.css'}
              media="print"
              onLoad="this.media='all'"
              key={getUniqueLinkKey()}
            />,
          ])}

          <noscript dangerouslySetInnerHTML={{
            __html: `
              <link
                rel="stylesheet"
                href={fontsUrl ?? '/fonts/fonts.css'}
                key={getUniqueLinkKey()}
              />
              `,
          }}
          />
          <link rel="apple-touch-icon" href={logoSquarePng?.src} key={getUniqueLinkKey()} />
          {
            isHomeTemplate && (
              <script type="application/ld+json" key={getUniqueLinkKey()}>
                {JSON.stringify({
                  '@type': 'WebSite',
                })}
              </script>
            )
          }
          {amp && (
            <link
              href="https://fonts.googleapis.com/css?family=Barlow:400,500,600,700|Barlow+Condensed:400,500,600,700&display=swap"
              rel="stylesheet"
              key={getUniqueLinkKey()}
            />
          )}
        </Helmet>
        {/* TODO: we need to use the withHelmet in the compose function here - you need to use getMappedContentType in the withHelmer, look at the props and get the mapped name; then you can remove them from the templates compose function */}
        {/* Can we use useContext and overwrite the CL context here? */}
        <PolarisApp
          config={{
            ...contextOverride(config, adConfig, amp),
            makeModelData: makeModelDataLoading
              ? get(config, 'globalSettings.productData', [])
              : get(makeModelData, 'getSettings.productData', []),
            metaData,
            disableBrowserRouter,
          }}
        >
          <div className={`polaris__app ${mappedContentType && contentTypeClass}`}>
            <Ad
              type="page-impression"
              id="page-impression"
              isPageImpression
            />
            <Header layoutType={layoutType} />
            {
              !isCommercialPage
              && !isCommercialPageTitleImage
              && !isLandingPage
              && getOOPAds()
            }
            <BelowHeaderAd />
            <Template {...props} />
            <Footer layoutType={layoutType} />
          </div>
        </PolarisApp>
      </>
    );
  };

  WrappedTemplate.propTypes = {
    adConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    amp: PropTypes.bool,
    client: PropTypes.shape().isRequired,
    config: PropTypes.shape(),
    dataLayer: PropTypes.shape().isRequired,
    metaData: PropTypes.shape().isRequired,
    permutive: PropTypes.shape(),
    layoutData: PropTypes.shape().isRequired,
  };

  WrappedTemplate.defaultProps = {
    adConfig: {},
    amp: false,
    config: {},
    permutive: {},
  };

  WrappedTemplate.displayName = 'withTemplate()';

  // Provides meta and config to template.
  return compose(withMeta, withPolaris)(WrappedTemplate);
};

export default withTemplate;
