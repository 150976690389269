import { compose } from 'recompose';
import { withTemplate, withHelmet } from 'Protons';
import { withPagination } from 'polaris-coreweb/exports';
import { getPaginationLimit } from 'SharedPartialsLocal';
import { AuthorQueryPagination as query } from './AuthorQuery.graphql';
import { Author } from './Author';

export default compose(
  withPagination({ query, queryVars: getPaginationLimit }),
  withTemplate,
  withHelmet('author'),
)(Author);
