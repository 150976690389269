import React from 'react';
import uniqid from 'uniqid';

import {
  getNextPages,
  getSocialSharingButtons,
  getTags,
} from 'SharedPartialsLocal';

import {
  getRelatedContent,
} from 'Helpers';

export const getArticleBottomComponents = ({
  associatedContent,
  siteUrl,
  socialSharingPlatforms,
  tags,
  title,
  url,
  layoutType,
}) => {
  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';

  const bottomComponents = [];
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  const tagsComp = !isCommercialPage && !isCommercialPageTitleImage && getTags(tags);

  const social = !isCommercialPage && !isCommercialPageTitleImage && getSocialSharingButtons({
    title,
    siteUrl,
    url,
    socialSharingPlatforms,
    extraClassNames: { 'polaris__article--social': true },
  });

  const tagsAndSocial = (
    <div className="polaris__content-bottom" key={uniqid()}>
      {tagsComp}
      {social}
    </div>
  );

  if (tagsComp || social) bottomComponents.push(tagsAndSocial);

  const nextPages = getNextPages({
    multipage, currentUrl: url, title: 'Continue Reading',
  });

  bottomComponents.push(nextPages);
  return bottomComponents;
};
