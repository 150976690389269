import React, { Fragment } from 'react';
import uniqid from 'uniqid';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { Tabs } from '@autovia-uk/polaris-components/components/molecules/Tabs';
import {
  getRatings,
} from 'SharedPartialsLocal';

const getContent = (body, ratingsForCurrentReview, review) => (
  <Fragment key={uniqid()}>
    <HtmlSafe html={body} />
    {getRatings(ratingsForCurrentReview)}
    <HtmlSafe html={review} />
  </Fragment>
);

const getTabsContent = (ownerReviewData) => {
  const data = ownerReviewData.map((el, index) => {
    const {
      title,
      body,
      ratings: ratingsForCurrentReview,
      review,
    } = el;

    const keyForFragment = `${title}${index}`;
    const content = getContent(body, ratingsForCurrentReview, review, keyForFragment);

    return {
      title,
      content,
    };
  });

  return data;
};

export const getTabs = ownerReviewData => (
  <Tabs
    tabs={getTabsContent(ownerReviewData)}
    visibleTabIndex={0}
  />
);
