import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { compose } from 'recompose';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { MultiPageNavigation } from '@autovia-uk/polaris-components/components/molecules/MultiPageNavigation';
import { getGoTo } from 'SharedPartials/getGoTo';
import { getNativeAd } from 'SharedPartials/getNativeAd';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getMultipageVehicleSpecs,
  getRightSideAdConfig,
  getSimilarProductContent,
} from 'Helpers';

import {
  getArticleGroup,
  getBreadcrumbs,
} from 'SharedPartialsLocal';
import VehicleSpecs from 'Templates/spec/VehicleSpecs';
import SpecList from 'Templates/spec/SpecList';
import SpecOutro from 'Templates/spec/SpecOutro';

// Template styles and imports
import 'Styles/components/templates/_Spec.scss';

/**
 * Get review page URL from spec page
 *
 * @param {*} url
 * @param {*} specTemplate
 */
export const getReviewURLFromSpecPage = (url, specTemplate) => {
  const pricesSpecsSlug = '/prices-specs';
  let reviewURLFromSpecPage = url.substring(0, url.indexOf(pricesSpecsSlug));
  if (specTemplate === 'specs') {
    reviewURLFromSpecPage += pricesSpecsSlug;
  }

  return reviewURLFromSpecPage;
};

/**
 * Get review page title from spec page
 *
 * @param {*} title
 * @param {*} specTemplate
 */
export const getReviewTitleFromSpecPage = (title, specTemplate) => {
  let reviewTitleFromSpecPage;
  if (specTemplate === 'specs') {
    reviewTitleFromSpecPage = 'All Models';
  } else {
    reviewTitleFromSpecPage = title.substring(0, title.indexOf(' : Prices & Specs'));

    // add "review" at end of title
    const titleSuffix = ' review';
    if (!reviewTitleFromSpecPage.endsWith(titleSuffix)) {
      reviewTitleFromSpecPage += titleSuffix;
    }
  }

  return reviewTitleFromSpecPage;
};

const SpecTemplate = (props) => {
  const {
    layoutData: {
      page: {
        associatedContent,
        breadcrumbs,
        isSponsored,
        kicker,
        subtitle,
        tags,
        title,
        url,
        vehicleSpecs,
        vehicleSpecList,
        contentType,
      },
    },
    amp,
    config: {
      siteUrl,
      social: {
        share: {
          platforms: socialSharingPlatforms,
        },
      },
    },
  } = props;

  const template = () => {
    if (contentType === 'VEHICLE_SPECS') {
      return 'specs';
    }
    return 'spec-list';
  };

  const renderSpec = () => {
    const trims = get(vehicleSpecList, 'trims', []);

    return (
      <>
        <div className="polaris__core-content polaris__core-content-spec polaris__content polaris__simple-grid">
          <div className="polaris__simple-grid--aside">
            <Ad {...getRightSideAdConfig(1)} />
          </div>
          <div className="polaris__simple-grid-main">
            <div className="polaris__content--wrapper">
              <MultiPageNavigation
                selectedUrl={url}
                content={getMultipageVehicleSpecs(trims)}
                showCurrentlyReadingText
                showIndex={false}
              />
            </div>
          </div>

          <VehicleSpecs
            vehicleSpecs={vehicleSpecs}
          />

          <div className="polaris__simple-grid--aside">
            <Ad {...getRightSideAdConfig(2)} />
          </div>

          <div className="polaris__simple-grid-main">
            <SpecOutro url={url} title={title} tags={tags} socialSharingPlatforms={socialSharingPlatforms} siteUrl={siteUrl} />
          </div>
        </div>
      </>
    );
  };

  const renderSpecList = () => {
    const specList = get(props, 'layoutData.page.vehicleSpecList.trims', null);

    if (specList === null) {
      return false;
    }

    return (
      <>
        <SpecList vehicleSpecList={specList} />
        <SpecOutro url={url} title={title} tags={tags} socialSharingPlatforms={socialSharingPlatforms} siteUrl={siteUrl} />
      </>
    );
  };

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            { getBreadcrumbs(breadcrumbs, isSponsored, kicker) }
            <Heading size={1} extraClassNames={{ '-content-title': true }}>
              { title }
            </Heading>
            {
              template === 'spec-list' && (
                <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
                  { subtitle }
                </Heading>
              )
            }
            {getGoTo(getReviewURLFromSpecPage(url, template), getReviewTitleFromSpecPage(title, template))}
          </div>
          {template === 'specs'
            ? renderSpec()
            : renderSpecList()}
          {/* Render Associated Contents */}
          <div className="polaris__core-content polaris__content">
            {getArticleGroup({
              title: 'Have you considered?',
              content: getSimilarProductContent(associatedContent),
              extraClassNames: { 'polaris__review--consider': true },
              articleCardProps: { datePosition: 'meta', showRating: true, excerpt: null },
            })}
            {getArticleGroup({
              title: 'Most Popular',
              content: getArticleGroupContent(associatedContent, 'mostPopular', {
                component: [
                  <React.Fragment key="native_article">
                    {getNativeAd({
                      id: 'native_article',
                      targeting: {
                        position: 'native_article',
                        placement: 'native_article',
                        refresh: 'no',
                      },
                    })}
                  </React.Fragment>,
                ],
              }),
              extraClassNames: { 'polaris__polaris__spec--most-popular': true },
              articleCardProps: {
                isHeading: false,
                showRating: true,
              },
            })}
          </div>
        </main>
      </div>
    </>
  );
};

SpecTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  amp: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.array,
      }),
    }),
  }).isRequired,
};

const MemoSpecTemplate = React.memo(SpecTemplate);

export default compose(
  withTemplate,
  withHelmet('spec'),
)(MemoSpecTemplate);
