import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { compose } from 'recompose';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import {
  Helmet,
} from 'polaris-coreweb/exports';

import {
  toAbsolute,
} from 'Helpers';

const withMeta = (Component) => {
  const WithMeta = (props) => {
    const {
      config: {
        branding: {
          logoImage,
        },
        globalSettings: {
          siteTitle,
          twitterSiteId,
          twitterHandle,
        },
        siteUrl,
      },
      metaData: {
        pageTitle = '',
        pageDescription = '',
        ogTitle = '',
        ogDescription = '',
        ogImage = '',
        twitterTitle = '',
        twitterDescription = '',
        twitterImage = '',
      },
      metaData: metaDataProps,
      dataLayer,
    } = props;

    const urlPath = get(props, 'layoutData.page.url');
    let fullUrl = urlPath && toAbsolute(urlPath, siteUrl);

    if (!fullUrl) {
      fullUrl = siteUrl;
    }

    let image = get(props, 'layoutData.page.teaserImage') || toAbsolute(logoImage.src, siteUrl);
    if (image && typeof image === 'object' && image.src) {
      image = image.src;
    }

    const twitterCardType = image ? 'summary_large_image' : 'summary';
    return (
      <>
        <Helmet titleTemplate={`%s | ${siteTitle}`} defaultTitle={siteTitle}>
          <meta name="description" content={pageDescription} />
          {twitterDescription && <meta property="twitter:description" content={twitterDescription || pageDescription} /> }
          <meta property="twitter:url" content={fullUrl} />
          <meta property="twitter:card" content={twitterCardType} />
          {twitterHandle && <meta property="twitter:site" content={twitterHandle} /> }
          {twitterTitle && <meta property="twitter:title" content={twitterTitle || pageTitle} /> }
          {twitterSiteId && <meta property="twitter:site:id" content={twitterSiteId} /> }
          {(twitterImage || image) && <meta property="twitter:image" content={twitterImage || image} /> }
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:url" content={fullUrl} />
          {ogTitle && <meta property="og:title" content={ogTitle || pageTitle} /> }
          {ogDescription && <meta property="og:description" content={ogDescription || pageDescription} /> }
          {(ogImage || image) && <meta property="og:image" content={ogImage || image} /> }
        </Helmet>
        <Component {...props} metaData={{ ...metaDataProps, pageTitle }} />
      </>
    );
  };
  WithMeta.propTypes = {
    config: PropTypes.shape({
      branding: PropTypes.shape({
        logoImage: PropTypes.shape(),
      }),
      siteUrl: PropTypes.string.isRequired,
      globalSettings: PropTypes.shape({
        siteTitle: PropTypes.string.isRequired,
        siteDescription: PropTypes.string.isRequired,
        twitterHandle: PropTypes.string,
        twitterSiteId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    metaData: PropTypes.shape({
      pageTitle: PropTypes.string,
      pageDescription: PropTypes.string,
      ogTitle: PropTypes.string,
      ogDescription: PropTypes.string,
      ogImage: PropTypes.string,
      twitterHandle: PropTypes.string,
      twitterTitle: PropTypes.string,
      twitterDescription: PropTypes.string,
      twitterImage: PropTypes.string,
    }),
    dataLayer: PropTypes.shape(),
  };
  WithMeta.defaultProps = {
    metaData: {
      pageTitle: '',
      pageDescription: '',
      ogTitle: '',
      ogDescription: '',
      ogImage: '',
      twitterHandle: '',
      twitterTitle: '',
      twitterDescription: '',
      twitterImage: '',
    },
    dataLayer: {},
  };
  WithMeta.displayName = 'withMeta()';

  const ComposedWithMeta = compose(withPolaris)(WithMeta);

  return ComposedWithMeta;
};

export default withMeta;
