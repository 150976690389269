import React from 'react';
import uniqid from 'uniqid';
import { Subscription } from '@autovia-uk/polaris-components/components/organisms/Subscription';
import 'Styles/components/organisms/_Subscription.scss';

export const getSubscription = (config) => {
  if (!config) return false;

  return (
    <Subscription
      key={uniqid()}
      image={config.image || null}
      mainTitle={config.mainTitle || ''}
      title={config.title || null}
      excerpt={config.excerpt || null}
      ctaLabel={config.ctaLabel || null}
      ctaUrl={config.ctaUrl || '#'}
      extraClassNames={config.extraClassNames || {}}
    />
  );
};
