/* eslint-disable consistent-return */
import React from 'react';
import uniqid from 'uniqid';

import { jsonOrString } from 'SharedHelpers/jsonOrString';
import { getComponentForBlockType } from './getComponentForBlockType';

const withWrapper = ({
  type,
}) => (Component) => {
  let extraClassName;

  if (type.indexOf('SIDE') !== -1) {
    extraClassName = 'aside';
  } else if (type.indexOf('INLINE') !== -1) {
    extraClassName = 'full';
  } else {
    extraClassName = 'main';
  }

  return (
    <div className={`polaris__simple-grid--${extraClassName}`} key={uniqid()}>
      {Component}
    </div>
  );
};

const isOneColumnLayout = ({ layoutType = '' }) => (layoutType ? ['adFreeTemplate', 'landingPage', 'commercialPage', 'commercialPageTitleImage', 'Full width', 'ownerReview'].includes(layoutType) : false);

const wrapWithBackground = (components, layoutType) => (
  <div className={`polaris__core-content${isOneColumnLayout({ layoutType }) ? ' -full-width' : ' polaris__simple-grid'}`}>
    {components}
  </div>
);

export const getComponentBlocks = (props, embedTypes, isAmp) => {
  let options = { props };
  let componentsWithoutDesktopAdsArray = [];
  let bodyArray = [];
  const finalArray = [];
  let wrappedAdsArray = [];
  let sideAd = null;
  const {
    bodyInjected: bodyBlocks,
    blocks,
    mappedContentType,
    bottomComponents = [],
    topComponents,
    layoutType,
    isMobile,
  } = props;

  if (typeof bodyBlocks === 'undefined' || !Array.isArray(bodyBlocks)) {
    return blocks;
  }

  bodyArray.push(topComponents);

  bodyBlocks.forEach((block, index) => {
    const isReactEl = React.isValidElement(block);
    const type = !isReactEl && (block.type || block.__typename);
    const data = !isReactEl && (block.data && jsonOrString(block.data));
    const isDesktopAd = !isMobile && type && (type.indexOf('DESKTOP_SIDE_ADVERT') !== -1 || type.indexOf('DESKTOP_INLINE_ADVERT') !== -1);
    const isMobileAd = isMobile && type && (type.indexOf('MOBILE_INLINE_ADVERT') !== -1);

    options = {
      ...options,
      mappedContentType,
      type,
      block,
      data,
      embedTypes,
      firstBlock: (bodyArray.length === 0),
      index,
      template: props.template || 'article',
      layoutType,
      isAmp,
      key: `bodyBlock_${index}`,
    };

    const component = isReactEl ? block : getComponentForBlockType(options);

    if (isDesktopAd || isMobileAd) {
      const wrappedAd = withWrapper(options)(component);

      // if inline ad
      if (componentsWithoutDesktopAdsArray.length > 0 && (type.indexOf('INLINE') !== -1)) {
        // Wrap non-inline ad components in a div with class 'polaris__simple-grid--main'
        const wrappedComponentsWithoutDesktopAd = withWrapper({
          ...options,
          type: 'main',
          key: `componentsWithoutDesktopAds_${index}`,
        })(componentsWithoutDesktopAdsArray);

        // Push this wrapped component to the bodyArray
        bodyArray.push(wrappedComponentsWithoutDesktopAd);

        // Add side ad if there is one
        if (sideAd) {
          bodyArray.push(sideAd);
          sideAd = null;
        }

        // Add the next layer of wrapping to  bodyArray all other components
        // Add these classes to the rest of the bodyArray components
        const wrappedBodyArray = wrapWithBackground(bodyArray, layoutType);

        // Push the wrapped bodyArray to the finalArray
        finalArray.push(wrappedBodyArray);

        // Push the wrapped ad to the wrapped Ads Array
        wrappedAdsArray.push(wrappedAd);
        // Push all wrapped ads to the finalArray
        wrappedAdsArray.forEach(el => finalArray.push(el));

        // Reset the arrays
        wrappedAdsArray = [];
        componentsWithoutDesktopAdsArray = [];
        bodyArray = [];

        return;
      }

      // if side ad
      sideAd = wrappedAd;
      return;
    }

    componentsWithoutDesktopAdsArray.push(component);
  });

  // adds components to the bottom of body - gives more space to side ad
  if (Array.isArray(bottomComponents) && bottomComponents.length > 0) {
    bottomComponents.forEach(el => componentsWithoutDesktopAdsArray.push(el));
  }

  if (componentsWithoutDesktopAdsArray.length > 0) {
    const wrappedComponentsWithoutDesktopAd = withWrapper({
      ...options,
      type: 'main',
      key: 'componentsWithoutDesktopAds_last',
    })(componentsWithoutDesktopAdsArray);
    bodyArray.push(wrappedComponentsWithoutDesktopAd);
    componentsWithoutDesktopAdsArray = [];
  }

  if (sideAd) {
    bodyArray.push(sideAd);
    sideAd = null;
  }

  // Add the next layer of wrapping to all bodyArray components currently in the bodyArray
  const wrappedBodyArray = wrapWithBackground(bodyArray, layoutType);
  // Push the wrapped bodyArray to the finalArray
  finalArray.push(wrappedBodyArray);

  return finalArray;
};
