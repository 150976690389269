import React from 'react';
import { NextSteps } from '@autovia-uk/polaris-components/components/molecules/NextSteps';
import 'Styles/components/molecules/_NextSteps.scss';

export const getNextSteps = (
  brochureLink = null,
  testDriveLink = null,
) => {
  if (brochureLink === null && testDriveLink === null) {
    return false;
  }

  const nextSteps = [];
  if (brochureLink) {
    nextSteps.push({
      url: brochureLink,
      title: 'Download a brochure',
      icon: 'polaris__ico-brochure',
    });
  }

  if (testDriveLink) {
    nextSteps.push({
      url: testDriveLink,
      title: 'Book a test drive',
      icon: 'polaris__ico-car',
    });
  }

  return (
    <NextSteps
      title="Next Steps"
      urls={nextSteps}
    />
  );
};
