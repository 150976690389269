import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getBody } from 'SharedPartials/getBody';
import { getGalleryRelatedArticleLink } from 'SharedPartials/getGalleryRelatedArticleLink';
import { getListGallery } from 'SharedPartials/getListGallery';
import { getNativeAd } from 'SharedPartials/getNativeAd';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
} from 'Helpers';

import {
  getArticleGroup,
  getBreadcrumbs,
  getPostMeta,
} from 'SharedPartialsLocal';

const GalleryTemplate = ({
  layoutData: {
    page,
  },
  dataLayer,
  config: {
    branding: {
      logoSquare: {
        src,
      },
    },
    globalSettings: {
      adSettings: {
        listGalleryAds,
      },
      name,
    },
    siteUrl,
  },
  amp,
}) => {
  const {
    associatedContent,
    body,
    breadcrumbs,
    galleryRelatedArticle,
    images,
    isSponsored,
    kicker,
    subtitle,
    title,
    url,
  } = page;

  // total number of items
  const totalContent = (images && images.length && Array.isArray(images)) ? images.length : 0;

  // Add Ad component (rules):
  // - after every 2nd media
  // mediaTypes: image, video, linked media
  // before rendering ads, check if there is enough content on the page and filter rules
  /**
   * Returns an array of even numbers limited by total number of items (totalContent),
   * plus one, which allows rendering the ad after last item if the last item is odd
   * @return {array}
   * @example [2, 4, 6, 8, 10, 12, 14, 16, ..., 2n, ... totalContent]
   */
  const advertPositions = [];
  for (let n = 1; n < (totalContent + 1); n += 1) {
    if (n % listGalleryAds === 0) {
      advertPositions.push(n);
    }
  }

  const adverts = advertPositions
    .filter(item => item <= totalContent)
    .map((e, i) => ({
      name: 'Ad',
      __typename: 'Advertisement',
      placement: e,
      component: (
        <Ad
          extraClassNames={{
            '-full-width': true,
          }}
          id={`refresh-inline_${i + 1}`}
          isSkippable
          isSpaceReserved
          key={`inline_${i + 1}`}
          label="Advertisement - Gallery continues below"
          targeting={{
            position: `inline_${i + 1}`,
            placement: `inline_${i + 1}`,
            refresh: 'yes',
          }}
          type="slot-1"
        />
      ),
    }));

  const mediaContentWithAds = [...images];

  adverts.map((e, i) => mediaContentWithAds.splice(e.placement + i, 0, e));

  const brandConfig = {
    name,
    logo: {
      src,
    },
  };

  return (
    <>
      <div className="polaris__body -gallery-template">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            {getBreadcrumbs(breadcrumbs, isSponsored, kicker)}

            <Heading size={1} extraClassNames={{ '-content-title': true }}>
              {title}
            </Heading>
            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>

            {getPostMeta(
              page,
              false,
              siteUrl,
              null,
              null,
              brandConfig,
            )}
          </div>

          <div className="polaris__core-content polaris__content -body-copy">
            <div className="polaris__content--full-width">
              {getBody({
                bodyInjected: body,
                template: 'gallery',
              })}
              {getGalleryRelatedArticleLink(galleryRelatedArticle)}
            </div>
          </div>

          <div className="polaris__core-content polaris__content -gallery">
            {getListGallery(mediaContentWithAds)}
          </div>

          <div className="polaris__core-content polaris__content">
            {getArticleGroup({
              title: 'Recommended',
              content: getArticleGroupContent(associatedContent, 'recommended'),
              articleCardProps: {
                headingSize: 4,
                showRating: true,
              },
            })}

            {/* Inject the Native ad in the Most Popular block as a last item */}
            {getArticleGroup({
              title: 'Most Popular',
              content: getArticleGroupContent(associatedContent, 'mostPopular', {
                component: [
                  <React.Fragment key="native_article">
                    {getNativeAd({
                      id: 'native_article',
                      targeting: {
                        position: 'native_article',
                        placement: 'native_article',
                        refresh: 'no',
                      },
                    })}
                  </React.Fragment>,
                ],
              }),
              extraClassNames: { 'polaris__gallery--most-popular': true },
              articleCardProps: {
                isHeading: false,
                showRating: true,
              },
            })}
          </div>
        </main>
      </div>
    </>
  );
};
GalleryTemplate.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    nodeId: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  config: PropTypes.shape({
    branding: PropTypes.shape({
      logoSquare: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    globalSettings: PropTypes.shape({
      adSettings: PropTypes.shape({
        listGalleryAds: PropTypes.number,
      }),
      name: PropTypes.string,
    }).isRequired,
    siteUrl: PropTypes.string.isRequired,
  }),
  amp: PropTypes.bool,
};

GalleryTemplate.defaultProps = {
  dataLayer: {},
  config: {
    globalSettings: {
      adSettings: {
        listGalleryAds: 2,
      },
    },
  },
  amp: false,
};

export default compose(
  withTemplate,
  withPolaris,
  withHelmet('gallery'),
)(GalleryTemplate);
