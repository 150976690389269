import { compose } from 'recompose';
import { withTemplate } from 'Protons/withTemplate';
import { withPagination } from 'polaris-coreweb/exports';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { getPaginationLimit } from 'SharedPartialsLocal';
import { IndexQueryPagination as query } from './IndexQuery.graphql';
import { withHelmet } from '../../protons';
import IndexTemplate from './IndexTemplate';

const ComposedIndexTemplate = compose(
  withPagination({ query, queryVars: getPaginationLimit }),
  withTemplate,
  withPolaris,
  withHelmet('index'),
)(IndexTemplate);

export default ComposedIndexTemplate;
