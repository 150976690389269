import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import {
  withMinimalTemplate,
} from 'Protons';
import { IntermediaryPage } from '@autovia-uk/polaris-components/components/templates/IntermediaryPage';

import 'Styles/components/templates/_IntermediaryTemplate.scss';

const IntermediaryTemplate = ({
  context: {
    cssPrefix,
  },
  config: {
    carwow: {
      valuationIntermediary: {
        desc,
        partnerLogo,
        redirectText,
        redirectLink,
        redirectTimeSecs,
        gaTracking,
      },
    },
  },
}) => (
  <div className={`${cssPrefix}__intermediarytemplate`}>
    {redirectLink
      && (
        <IntermediaryPage
          desc={desc}
          partnerLogo={partnerLogo}
          redirectText={redirectText}
          redirectLink={redirectLink}
          redirectTimeSecs={redirectTimeSecs}
          gaTracking={gaTracking}
          brandingLogo={{
            src: '/public/logo-evo-square.png',
            height: 160,
            width: 160,
          }}
        />
      )
    }
  </div>
);

IntermediaryTemplate.defaultProps = {
  context: {},
  config: {
    carwow: {
      valuationIntermediary: {
        desc: '',
        redirectText: '',
        partnerLogo: {},
      },
    },
    branding: {
      logoImage: {},
    },
  },
};

IntermediaryTemplate.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  config: PropTypes.shape(),
};

export default compose(
  withMinimalTemplate,
  withPolaris,
)(IntermediaryTemplate);
