/**
* @render react
* @name Table
*/

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { compose } from 'recompose';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { withAmp } from '@autovia-uk/polaris-components/components/protons/withAmp';
import renderAmp from './amp';
import './_style.scss';

/**
 * Table component. thead must always be defined.
 *
 * @param {string} mode - The tablesaw mode used: swipe, stack, columntoggle
 * @param {node} children - Components to be added as a child to the component
 * @param {boolean} loadStyles - Whether to apply the class names for styling.
 * @param {Object} extraClassNames - Extra class names to be applied to the component
 * @param {Object} context - Extra information coming from Polaris provider.
 */
class Table extends PureComponent {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    // Create ref for our table
    this.tableRef = React.createRef();
  }

  /**
   * Once component is mounted
   */
  componentDidMount() {
    if (typeof window !== 'undefined') {
      const { current: currentTableRef } = this.tableRef;

      if (currentTableRef) {
        const tableEl = currentTableRef.querySelector('table');
        let theadEl = null;

        if (tableEl) {
          theadEl = tableEl.querySelector('thead');
        }

        if (theadEl && theadEl.childNodes.length === 0) {
          // we have an empty thead, thus we move the first row within the thead section
          const tbodyEl = tableEl.querySelector('tbody');
          const firstTrEl = tbodyEl.querySelector('tr:first-child');
          const firstTrTdEl = firstTrEl.querySelectorAll('td');
          const totalTd = firstTrTdEl.length;
          const theadTrEl = document.createElement('tr');

          let i = 0;
          for (i = 0; i < totalTd; i += 1) {
            const th = document.createElement('th');
            th.innerHTML = firstTrTdEl[i].innerHTML;
            theadTrEl.appendChild(th);
          }
          theadEl.appendChild(theadTrEl);

          // remove first row
          tbodyEl.removeChild(firstTrEl);
        }

        // Initializes tablesaw
        if (typeof window.Tablesaw === 'undefined') {
          import('tablesaw').then((module) => {
            module._init(tableEl);
          }).catch(error => false); // eslint-disable-line
        } else {
          window.Tablesaw._init(tableEl);
        }
      }
    }
  }

  /**
   * Renders the amp version of the component.
   */
  amp() {
    return renderAmp.bind(this)();
  }

  /**
   * Enhances table to add tablesaw specifics
   */
  enhanceTable() {
    const { mode, children, context: { platform } } = this.props;

    if (platform === 'amp') {
      return children;
    }

    // Check if we have a thead tag to tablesaw to work.
    let childrenProps = children;
    let hasTheadtag = false;

    React.Children.forEach(children, (child) => {
      if (child.type === 'table') {
        if (child.props.children) {
          React.Children.forEach(child.props.children, (son) => {
            if (son.type === 'thead') {
              hasTheadtag = true;
            }
          });
        }
      } else if (typeof child.type === 'function' && child.props.html) {
        // if we are using HtmlSafe component
        const htmlTable = child.props.html;
        childrenProps = ReactHtmlParser(htmlTable);

        if (childrenProps[0] && childrenProps[0].props) {
          React.Children.forEach(childrenProps[0].props.children, (son) => {
            if (son.type === 'thead') {
              hasTheadtag = true;
            }
          });
        }
      }
    });

    // Enhance the table tag to add tablesaw specifics
    const updatedChildren = React.Children.map(childrenProps, (child) => {
      // If we have an existing table tag in the children
      if (child.type === 'table') {
        const childProps = {
          className: `tablesaw ${child.props.className || ''}`,
        };

        if (hasTheadtag) {
          childProps.className = `${childProps.className} tablesaw-${mode} -${mode}-mode`;
          childProps['data-tablesaw-mode'] = mode;
        }

        // We add additional class names and ref.
        return React.cloneElement(child, childProps);
      }

      return child;
    });

    return updatedChildren;
  }

  /**
   * Render function
   */
  render() {
    const {
      loadStyles,
      extraClassNames,
      context: { cssPrefix },
    } = this.props;

    // Enhance table to add tablesaw attributes
    const enhancedTable = this.enhanceTable();

    return (
      <div
        className={
          classNames({
            [`${cssPrefix}__table`]: loadStyles,
            ...extraClassNames,
          })
        }
        ref={this.tableRef}
      >
        {enhancedTable}
      </div>
    );
  }
}

/**
 * Valid props
 */
Table.propTypes = {
  mode: PropTypes.oneOf(['stack', 'swipe', 'columntoggle']),
  children: PropTypes.node,
  loadStyles: PropTypes.bool,
  ampRowHeight: PropTypes.number, // eslint-disable-line
  extraClassNames: PropTypes.shape(),
  context: PropTypes.shape(),
  ampScripts: PropTypes.shape(), // eslint-disable-line
};

/**
 * Default props
 */
Table.defaultProps = {
  mode: 'swipe', // values can be swipe, stack, columntoggle
  children: [],
  ampRowHeight: 55,
  loadStyles: true,
  extraClassNames: {},
  context: {},
  ampScripts: {
    'amp-carousel': 'https://cdn.ampproject.org/v0/amp-carousel-0.1.js',
  },
};

export default compose(withPolaris, withAmp)(Table);
