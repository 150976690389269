/* eslint-disable react/prop-types */
import React from 'react';
import { Social } from '@autovia-uk/polaris-components/components/molecules/Social';
import 'Styles/components/molecules/_Social.scss';

export const getSocialSharingButtons = ({
  extraClassNames,
  siteUrl,
  socialSharingPlatforms,
  title,
  url,
}) => {
  if (siteUrl === null || url === null || title === null) {
    return false;
  }

  return (
    <Social
      url={`${siteUrl}${url}`}
      title={title}
      platforms={socialSharingPlatforms}
      extraClassNames={extraClassNames}
    />
  );
};
