import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';
import uniqid from 'uniqid';
import { Collapsible } from '@autovia-uk/polaris-components/components/molecules/Collapsible';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';

/**
 * Convert camelCaseText to Sentence Case Text
 */
export function formatString(string) {
  const result = string.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const VehicleSpecs = ({
  vehicleSpecs,
}) => {
  const {
    summary,
    technicalData,
    standardEquipment,
  } = vehicleSpecs;

  const renderSummary = () => (
    <Table mode="stack">
      <table>
        <tbody>
          {Object.entries(summary).map(([key, value]) => {
            let tableHeader;
            switch (key) {
              case 'CC':
                tableHeader = 'Engine Capacity - CC';
                break;
              case 'co2':
                tableHeader = 'WLTP - CO2 (g/km) - Comb';
                break;
              case 'bhp':
                tableHeader = 'Engine Power - BHP';
                break;
              case 'mpg':
                tableHeader = 'WLTP - MPG - Comb';
                break;
              case 'luggageUp':
                tableHeader = 'Luggage Capacity (Seats Up)';
                break;
              case 'kerbweightMin':
                tableHeader = 'Minimum Kerbweight';
                break;
              case 'aer':
                tableHeader = key.toUpperCase();
                break;
              default:
                tableHeader = formatString(key);
                break;
            }

            const incorrectValues = ['null mm', 'null l', 'Not Available mm', 'null kg', 'VehicleSummary'];

            if ((value
              && (value === ''
                || incorrectValues.includes(value)
                || (key === 'price' && value === null))
            ) || (typeof value === 'object' && !value)
            ) {
              return false;
            }

            return (
              <tr key={uniqid()}>
                <td>{tableHeader}</td>
                <td>
                  {key === 'price'
                    ? `£${value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  );
  const renderTechnicalData = () => technicalData
    .map((groupData) => {
      const {
        group: groupTitle,
        items,
      } = groupData;
      return (
        <React.Fragment key={uniqid()}>
          <Heading size={2} extraClassNames={{ h3: true }}>{groupTitle}</Heading>
          <Table mode="stack">
            <table>
              <tbody>
                {items.map(item => (
                  <tr key={uniqid()}>
                    <td>{item.key}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Table>
        </React.Fragment>
      );
    });

  const renderEquipmentData = () => standardEquipment
    .map((groupData) => {
      const {
        group: groupTitle,
        items,
      } = groupData;
      return (
        <React.Fragment key={uniqid()}>
          <Heading size={2} extraClassNames={{ h3: true }}>{groupTitle}</Heading>
          <ul>
            {items.map(item => (
              <li key={uniqid()}>{item}</li>
            ))}
          </ul>
        </React.Fragment>
      );
    });

  return (
    <>
      <div className="polaris__simple-grid-main">
        <Collapsible
          key={uniqid()}
          title="Summary"
          content={renderSummary()}
          isOpen
          iconOpen="polaris__ico-pro"
          iconClose="polaris__ico-con"
        />

        <Collapsible
          key={uniqid()}
          title="Equipment"
          content={renderEquipmentData()}
          isOpen={false}
          iconOpen="polaris__ico-pro"
          iconClose="polaris__ico-con"
        />
      </div>

      <div className="polaris__simple-grid--full">
        <Ad
          id="refresh-inline_1"
          isSpaceReserved
          type="slot-1"
        />
      </div>

      <div className="polaris__simple-grid-main">
        <Collapsible
          key={uniqid()}
          title="Technical"
          content={renderTechnicalData()}
          isOpen={false}
          iconOpen="polaris__ico-pro"
          iconClose="polaris__ico-con"
        />
      </div>
    </>
  );
};

VehicleSpecs.propTypes = {
  vehicleSpecs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.object]).isRequired,
    technicalData: PropTypes.arrayOf(PropTypes.object),
    standardEquipment: PropTypes.arrayOf(PropTypes.object),
  }),
};

VehicleSpecs.defaultProps = {
  vehicleSpecs: {
    summary: [],
    technicalData: [],
    standardEquipment: [],
  },
};

export default VehicleSpecs;
