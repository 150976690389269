import get from 'lodash.get';

/**
 * @param {Object} config global config object
 * @returns {Object} object containing data for Amp ad rtc config
 */
export const getRtcConfig = (config) => {
  const urls = [];
  const permutiveNamespace = get(config, 'globalSettings.permutive.namespace', null);
  if (permutiveNamespace) urls.push(`https://${permutiveNamespace}.amp.permutive.com/rtc?type=doubleclick`);
  return { urls };
};
