import { compose, withState, withProps } from 'recompose';

import { withPagination } from 'polaris-coreweb/exports';

import {
  withTemplate,
  withHelmet,
} from 'Protons';

import { SearchQueryPagination as query } from './SearchQuery.graphql';

import SearchTemplate from './SearchTemplate';

const ComposedSearchTemplate = compose(
  withState(
    'searchPath',
    'setSearchPath',
    ({ location: { pathname } }) => pathname,
  ),
  withProps(({ searchPath }) => ({ location: { pathname: searchPath } })),
  withPagination({ query }),
  withTemplate,
  withHelmet('search'),
)(SearchTemplate);

export default ComposedSearchTemplate;
