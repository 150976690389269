import React from 'react';
import { MultiPageNavigation } from '@autovia-uk/polaris-components/components/molecules/MultiPageNavigation';
import 'Styles/components/molecules/_MultiPageNavigation.scss';

export const getMultipageNavigation = (
  multipage = false,
  currentUrl = null,
) => {
  if (!multipage || (Array.isArray(multipage) && multipage.length === 0)) {
    return false;
  }

  return (
    <MultiPageNavigation
      selectedUrl={currentUrl}
      content={multipage}
      layout="expanded"
      stickyEnabled
      showIndex={false}
      showCurrentlyReadingText
      stickyActiveClass="polaris__multi-page-navigation--sticky"
    />
  );
};
