import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, withProps, setDisplayName } from 'recompose';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import merge from 'deepmerge';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Hero } from '@autovia-uk/polaris-components/components/organisms/Hero';
import { withPagination } from 'polaris-coreweb/exports';
import { getBody } from 'SharedPartials/getBody';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getSponsorInfo } from 'SharedPartials/getSponsorInfo';
import { getIndexArticlePageContent } from 'SharedPartials/getIndexArticlePageContent';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getIndex,
  getRelatedContent,
} from 'Helpers';

import {
  getArticleBottomComponents,
  getArticleGroup,
  getBreadcrumbs,
  getDisqusCollapsible,
  getIndexArticlePageHeader,
  getInThisArticle,
  getMultipageNavigation,
  getPostMeta,
  getPrimaryMedia,
  getPromoBoxSettings,
} from 'SharedPartialsLocal';

import rules from '@autovia-uk/injection-default-rules-set';
import injectionEngine from '@autovia-uk/injection-engine';
import { getAfterContentAd } from 'SharedPartials/getAfterContentAd';
import { countBodyInjectedInlineAds } from 'SharedHelpers/countBodyInjectedInlineAds';
import { ArticleQueryPagination as query } from './ArticleQuery.graphql';

// Template styles and imports
import 'Styles/components/templates/_Article.scss';

const Article = (props) => {
  const {
    amp,
    config: {
      branding: {
        logoSquare: {
          src,
        },
      },
      breakpoints,
      globalSettings: {
        adSettings,
        adSettings: { afterContentDesktopAds, afterContentMobileAds },
        disqusShortname,
        enableComments,
        name,
        promoBoxSettings,
      },
      newsletterConfig,
      siteUrl,
      social: {
        share: {
          platforms: socialSharingPlatforms,
        },
      },
    },
    dataLayer,
    layoutData: {
      page: {
        associatedContent,
        body,
        breadcrumbs,
        filters,
        heroImage,
        isSponsored,
        kicker,
        layoutType,
        monetising,
        primaryMedia,
        showFilters,
        sponsor: sponsorInfo,
        subtitle,
        tags,
        title,
        url,
      },
      page,
    },
  } = props;

  const brandConfig = {
    name,
    logo: {
      src,
    },
  };

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';
  const { heroImage: heroImageDesktop, heroImageMobile } = heroImage || {};
  const hasHeroImage = Object.keys(heroImage).length > 0;

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const { nodeId } = dataLayer;
  const indexData = getIndex(props);
  const isIndexArticlePage = indexData !== false;
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  let bodyInjected = injectionEngine({
    body,
    rules,
    amp,
    props: {
      adSettings,
      breakpoints,
      monetising,
      newsletterConfig,
      pageContentType: 'article',
      promoBoxSettings: getPromoBoxSettings({
        promoBoxSettings,
        ppc: promoBoxSettings.subscriptionArticle,
      }),
      amp,
    },
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const postMeta = getPostMeta(
    page,
    enableComments,
    siteUrl,
    dataLayer,
    disqusShortname,
    brandConfig,
  );

  const topComponents = (
    <div className="polaris__simple-grid--main">
      {getMultipageNavigation(multipage, url)}
      {!isCommercialPage && !isCommercialPageTitleImage && getPrimaryMedia(primaryMedia, '-article')}
      {!isCommercialPage && !isCommercialPageTitleImage && getSponsorInfo({ sponsor: sponsorInfo })}
    </div>
  );

  return (
    <>
      <div
        className={classNames({
          polaris__body: true,
          polaris__commercialpage: isCommercialPage,
          polaris__commercialpagetitleimage: isCommercialPageTitleImage,
        })}
      >
        <main className="polaris__main" id="main">
          {(isCommercialPage || isCommercialPageTitleImage) && (
            <Hero
              images={[
                {
                  ...heroImageDesktop,
                  size: 'hero-feature-desktop',
                },
                {
                  ...heroImageMobile,
                  size: 'hero-feature-mobile',
                },
              ]
              }
              renderPictureTag
              showPrimaryMedia={!!hasHeroImage}
              title={isCommercialPageTitleImage ? title : ''}
              extraClassNames={{
                'polaris__commercialpage-hero': true,
                'polaris__commercialpagetitleimage-hero': isCommercialPageTitleImage,
                '-no-image': !hasHeroImage,
              }}
            />
          )}

          <div className="polaris__content polaris__core-content">
            {
              (isCommercialPage || isCommercialPageTitleImage)
                ? (
                  <div>
                    <div className="polaris__commercialpage-sponsor">
                      {getSponsorInfo({ sponsor: sponsorInfo })}
                    </div>
                    <div className="polaris__commercialpage-meta">
                      {isSponsored && getBreadcrumbs([], isSponsored, kicker)}
                      {postMeta}
                    </div>
                  </div>
                )
                : getBreadcrumbs(breadcrumbs, isSponsored, kicker)
            }

            {!isCommercialPageTitleImage && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}

            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>

            {!isCommercialPage && !isCommercialPageTitleImage && postMeta}
          </div>

          <div className={`polaris__content ${isCommercialPage || isCommercialPageTitleImage ? '-full-width' : ''} -body-copy`}>
            {getBody({
              bodyInjected,
              template: 'article',
              layoutType,
              bottomComponents: getArticleBottomComponents({
                siteUrl,
                associatedContent,
                tags,
                title,
                url,
                socialSharingPlatforms,
                layoutType,
              }),
              topComponents,
              isMobile,
            })}

            {!isCommercialPage && !isCommercialPageTitleImage && (
              <div className="polaris__core-content polaris__simple-grid--main">
                {getInThisArticle('Continue Reading', url, multipage)}
                {!amp
                    && enableComments
                    && getDisqusCollapsible(
                      siteUrl,
                      url,
                      title,
                      nodeId,
                      disqusShortname,
                    )}
              </div>
            )}
          </div>
        </main>
      </div>

      {
        !isCommercialPage
        && !isCommercialPageTitleImage
        && (
          <div className="polaris__content polaris__core-content">
            {getArticleGroup({
              title: 'Recommended',
              content: getArticleGroupContent(associatedContent, 'recommended'),
              extraClassNames: { 'polaris__article--consider': true },
              articleCardProps: {
                headingSize: 4,
                showRating: true,
              },
            })}
            {(afterContentDesktopAds || afterContentMobileAds) && (getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds }))}
            {!isIndexArticlePage
                && getArticleGroup({
                  title: 'Most Popular',
                  content: getArticleGroupContent(associatedContent, 'mostPopular', {
                    component: [
                      <React.Fragment key="native_article">
                        {getNativeAd({
                          id: 'native_article',
                          targeting: {
                            position: 'native_article',
                            placement: 'native_article',
                            refresh: 'no',
                          },
                        })}
                      </React.Fragment>,
                    ],
                  }),
                  extraClassNames: { 'polaris__article--most-popular': true },
                  articleCardProps: {
                    isHeading: false,
                    showRating: true,
                  },
                })}
            {isIndexArticlePage && (
              <>
                {getIndexArticlePageHeader({
                  indexData,
                  propsData: props,
                  showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
                  taxonomyFilterParams,
                })}
                {getIndexArticlePageContent(
                  indexData,
                  {
                    ...props,
                    articleCardProps: {
                      datePosition: 'bottom',
                      headingSize: 4,
                      showRating: true,
                    },
                    articlesPerRow: 4,
                  },
                  countInlineAds,
                )}
              </>
            )}
          </div>
        )
      }
    </>
  );
};

Article.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
  amp: PropTypes.bool,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    LoadMore: PropTypes.func,
    pages: PropTypes.object,
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    nextUrl: PropTypes.string,
    page: PropTypes.number,
  }).isRequired,
  config: PropTypes.shape({
    ads: PropTypes.shape(),
    branding: PropTypes.shape({
      logoSquare: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    globalSettings: PropTypes.shape({
      adSettings: PropTypes.shape({
        afterContentDesktopAds: PropTypes.bool,
        afterContentMobileAds: PropTypes.bool,
        afterNavigationDesktopAds: PropTypes.bool,
        afterNavigationMobileAds: PropTypes.bool,
        listGalleryAds: PropTypes.number,
        minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
        minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
        minNumOfWordsBeforeTeads: PropTypes.number,
        numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
        numberOfWordsBetweenInlineMobileAds: PropTypes.number,
        numberOfWordsForFirstInjectionDesktop: PropTypes.number,
        numberOfWordsForFirstInjectionMobile: PropTypes.number,
        refreshBlacklist: PropTypes.string,
        refreshSiteWide: PropTypes.bool,
        refreshTime: PropTypes.number,
      }),
      disqusShortname: PropTypes.string,
      enableComments: PropTypes.bool,
      monetising: PropTypes.arrayOf(
        PropTypes.shape({
          config: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
          enabled: PropTypes.bool,
          service: PropTypes.string,
        }),
      ),
      name: PropTypes.string,
      promoBoxSettings: PropTypes.shape(),
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.array,
      }),
    }),
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    nodeId: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Article.defaultProps = {
  amp: false,
  dataLayer: {},
  location: {
    search: '',
  },
};

const setPageNumber = compose(
  setDisplayName('setTotalPages()'), // Sets the display name for ease of debugging.
  withProps((props) => {
    const {
      layoutData: {
        page: { associatedContent },
      },
      data,
    } = props;
    const index = associatedContent.find(
      ({ type }) => type === 'indexArticlePageList',
    );
    if (index && index.pagination && typeof index.pagination === 'object') {
      const {
        pagination: { totalPages },
      } = index;
      return { ...props, data: merge(data, { pagination: { totalPages } }) };
    }
    return {};
  }),
);

export default compose(
  setPageNumber,
  withPagination({
    query,
    path: ({ associatedContent }) => getRelatedContent(associatedContent, 'indexArticlePageList'),
  }),
  withTemplate,
  withPolaris,
  withHelmet('article'),
)(Article);
