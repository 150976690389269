import {
  Article,
  Author,
  BasicPage,
  Content,
  ErrorPage,
  Gallery,
  Home,
  IndexTemplate,
  Loading,
  NotFound,
  OwnerReview,
  Page,
  Review,
  Search,
  Spec,
  IntermediaryTemplate,
} from 'Templates';

export const layouts = {
  ARTICLE: Article,
  AUTHOR_INDEX: Author,
  BASIC_PAGE: BasicPage,
  BESTS: Article,
  error: ErrorPage,
  GALLERY: Gallery,
  HOMEPAGE: Home,
  INDEX_ARTICLE_PAGE: Article,
  INDEX_PAGE: IndexTemplate,
  loading: Loading,
  NEWS: Article,
  notFound: NotFound,
  OWNER_REVIEW: OwnerReview,
  PAGINATED_REVIEW_VERDICT: Review,
  PAGINATED_REVIEW: Review,
  REVIEW: Review,
  SEARCH_INDEX: Search,
  TAXONOMY_INDEX: IndexTemplate,
  VEHICLE_SPECLIST: Spec,
  VEHICLE_SPECS: Spec,
  INTERMEDIARY_PAGE: IntermediaryTemplate,
};

export const ampLayouts = {
  ARTICLE: true,
  BASIC_PAGE: true,
  GALLERY: true,
  NEWS: true,
  REVIEW: true,
};
