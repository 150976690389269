import React, { Fragment } from 'react';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { WhichIsBest } from '@autovia-uk/polaris-components/components/molecules/WhichIsBest';
import 'Styles/components/molecules/_WhichIsBest.scss';

const mapWhichIsBest = obj => typeof obj === 'object' && Object.keys(obj).map(el => (obj[el] ? {
  title: obj[el].key,
  value: obj[el].value,
} : false)).filter(item => item !== false);

export const getWhichIsBest = (vehicleBests, title = 'Which Is Best', specsPath = null, extraClassNames = null) => {
  if (vehicleBests === null) {
    return false;
  }

  const columns = [];

  const cheapest = vehicleBests.cheapest ? mapWhichIsBest(vehicleBests.cheapest) : false;
  if (cheapest) {
    columns.push({
      title: 'Cheapest',
      tooltipTitle: 'Cheapest vehicles',
      tooltipContent: 'Our \'cheapest\' pick is the model with the lowest on the road retail price.',
      tooltipIcon: 'polaris__ico-info',
      features: cheapest,
    });
  }

  const economical = vehicleBests.economical ? mapWhichIsBest(vehicleBests.economical) : false;
  if (economical) {
    columns.push({
      title: 'Most Economical',
      tooltipTitle: 'Most economical vehicles',
      tooltipContent: 'Our \'most economical\' pick is the model with the best fuel economy on the WLTP combined cycle.',
      tooltipIcon: 'polaris__ico-info',
      features: economical,
    });
  }

  const fastest = vehicleBests.fastest ? mapWhichIsBest(vehicleBests.fastest) : false;
  if (fastest) {
    columns.push({
      title: 'Fastest',
      tooltipTitle: 'Fastest vehicles',
      tooltipContent: 'Our \'fastest\' pick is the model with the fastest time for the 0-62mph or 0-60mph sprint.',
      tooltipIcon: 'polaris__ico-info',
      features: fastest,
    });
  }

  const ourPick = vehicleBests.ourPick ? mapWhichIsBest(vehicleBests.ourPick) : false;
  if (ourPick) {
    columns.push({
      title: 'Our Pick',
      tooltipTitle: 'Our pick vehicles',
      tooltipContent: '\'Our pick\' is the model we think offers the best balance of price, specification, economy and performance in the range.',
      tooltipIcon: 'polaris__ico-info',
      features: ourPick,
    });
  }

  if (columns.length === 0) {
    return false;
  }

  return (
    <WhichIsBest
      extraClassNames={extraClassNames}
      title={title}
      description={specsPath && <Link href={specsPath}>See More Stats</Link>}
      columns={columns}
    />
  );
};
