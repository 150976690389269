import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import uniqid from 'uniqid';

import {
  withTemplate,
  withHelmet,
} from 'Protons';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import {
  getPromoBoxSettings,
} from 'SharedPartialsLocal';

import {
  getHomeContentBlocks,
} from './homeHelpers';

/** Site specific SASS overrides */
import 'Styles/components/templates/_Home.scss';
import 'Styles/components/organisms/_ArticleGroup.scss';
import 'Styles/components/molecules/_ArticleCard.scss';
import 'Styles/components/_commons.scss';

const Home = ({
  layoutData: { page: { associatedContent } },
  config: {
    newsletterConfig,
    globalSettings: {
      promoBoxSettings,
    },
  },
}) => {
  const config = {
    promoBoxConfig: getPromoBoxSettings({
      promoBoxSettings,
      ppc: promoBoxSettings.subscriptionHomepage,
    }),
    newsletterConfig,
  };
  const components = getHomeContentBlocks(associatedContent, config);

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          {components && components.length && Array.isArray(components) && components.map(component => (
            <React.Fragment key={uniqid()}>
              {component}
            </React.Fragment>
          ))}
        </main>
      </div>
    </>
  );
};

Home.propTypes = {
  layoutData: PropTypes.shape().isRequired,
  config: PropTypes.shape().isRequired,
};
Home.defaultProps = {};

export default compose(
  withTemplate,
  withPolaris,
  withHelmet('home'),
)(Home);
