/**
 * @render react
 * @name ReviewList
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqid from 'uniqid';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Rating } from '@autovia-uk/polaris-components/components/molecules/Rating';
import { Review } from '@autovia-uk/polaris-components/components/organisms/Review';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import './_style.scss';

/**
 * ReviewList component
 *
 * @param {string} title - The title of the section
 * @param {number} headingSize - Size of the heading
 * @param {string} overallRatingLabel - Label for overall rating. Defaults to "Overall Rating"
 * @param {integer} overallRating - Overall rating value
 * @param {boolean} showTextRating - Whether to show the text rating
 * @param {string} summary - Summary of the review list
 * @param {array} reviews - Arrays of objects with the following keys: title, url, review, description, ratings
 * @param {boolean} loadStyles - Whether to apply the class names for styling
 * @param {Object} extraClassNames - Extra classes to be added to the component
 * @param {Object} context - Extra information coming from Polaris provider
 */

const ReviewList = (
  {
    title,
    headingSize,
    overallRatingLabel,
    overallRating,
    showTextRating,
    summary,
    reviews,
    loadStyles,
    context: { cssPrefix },
    extraClassNames,
  },
) => (
  <div className={
    classNames({
      [`${cssPrefix}__review-list`]: loadStyles,
      ...extraClassNames,
    })
}
  >
    <div className={`${cssPrefix}__review-list--header`}>
      <Heading size={headingSize}>{title}</Heading>

      {overallRating && (
        <Rating label={overallRatingLabel} rating={overallRating} showTextRating={showTextRating} />
      )}
    </div>

    {(summary || reviews.length > 0) && (
      <div className={`${cssPrefix}__review-list--body`}>
        {summary && (
          <div className={`${cssPrefix}__review-list--summary`}>
            {summary}
          </div>
        )}

        {reviews.length > 0 && (
          <div className={`${cssPrefix}__review-list--reviews`}>
            { reviews.map((review, index) => (
              <Review
                key={uniqid(`review-${index}`)}
                url={review.url}
                title={review.title}
                description={review.description}
                ratings={review.ratings}
                showCustomRating={false}
              />
            )) }
          </div>
        )}
      </div>
    )}
  </div>
);

/**
 * Valid props
 */
ReviewList.propTypes = {
  title: PropTypes.string,
  headingSize: PropTypes.number,
  overallRatingLabel: PropTypes.string,
  overallRating: PropTypes.number,
  showTextRating: PropTypes.bool,
  summary: PropTypes.string,
  reviews: PropTypes.arrayOf(PropTypes.object),
  context: PropTypes.shape(),
  loadStyles: PropTypes.bool,
  extraClassNames: PropTypes.shape(),
};

/**
 * Default props
 */
ReviewList.defaultProps = {
  title: '',
  headingSize: 3,
  overallRatingLabel: 'Overall rating',
  overallRating: null,
  showTextRating: true,
  summary: null,
  reviews: [],
  context: {},
  loadStyles: true,
  extraClassNames: {},
};

export default withPolaris(ReviewList);
