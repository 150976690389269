/**
 * @render react
 * @name Header
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { ProductSelector } from '@autovia-uk/polaris-components/components/molecules/ProductSelector';
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch';

import './_style.scss';

const Header = (props) => {
  const {
    context: {
      cssPrefix,
      platform,
      header: {
        navigation,
      },
      branding: {
        logoImage,
      },
    },
    layoutType,
  } = props;
  const searchRef = React.createRef();
  const [isOpen, setOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const isLandingPage = layoutType === 'landingPage';

  const onClick = () => {
    setOpen(!isOpen);
    document.documentElement.classList.toggle('-menu-open');
  };

  const onSearchClick = () => {
    setSearchOpen(!isSearchOpen);
    setOpen(false);
    document.documentElement.classList.remove('-menu-open');
  };

  const onSearchSubmit = () => {
    onSearchClick();
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && isSearchOpen) {
      if (searchRef && searchRef.current) {
        const inputEl = searchRef.current.querySelector('input[type="search"]');
        setTimeout(() => {
          inputEl.focus();
        }, 0);
      }
    }
  }, [isSearchOpen]);
  return (
    <>
      <div className="screen-reader-text">
        <a href="#main">Skip to Content</a>
        <a href="#footer">Skip to Footer</a>
      </div>
      <header
        className={classNames({
          [`${cssPrefix}__header`]: true,
          [`${cssPrefix}__header-landing`]: isLandingPage,
        })}
        id="header"
      >
        <div
          className={classNames({
            [`${cssPrefix}__header--inner`]: true,
            '-is-menu-open': isOpen,
            '-is-search-open': isSearchOpen,
          })}
          // eslint-disable-next-line
          data-amp-bind-class={platform === 'amp' ? `(isSearchOpen ? '-is-search-open ${cssPrefix}__header--inner': '${cssPrefix}__header--inner') + ' ' + (isMenuOpen ? '-is-menu-open ${cssPrefix}__header--inner': '${cssPrefix}__header--inner')` : undefined}
        >
          <div
            className={classNames({
              [`${cssPrefix}__header--logo`]: true,
              [`${cssPrefix}__header--logo-landing`]: isLandingPage,
            })}
          >
            <Link href="/">
              <Image
                alt={logoImage.alt}
                src={logoImage.src}
                height={logoImage.height}
                width={logoImage.width}
                lazyLoad={false}
              />
            </Link>
          </div>

          {!isLandingPage && (
            <div
              className={classNames({
                [`${cssPrefix}__header--navigation-search`]: true,
              })}
            >
              {navigation && (
                <Navigation
                  id="polaris__top-menu"
                  content={navigation}
                  ampOnButtonClick="tap:AMP.setState({ isMenuOpen: !isMenuOpen })"
                  onButtonClick={onClick}
                  navigationClassName={`${cssPrefix}__header--navigation`}
                  buttonClassName={`${cssPrefix}__header--menu-button`}
                  subMenuIcon={`${cssPrefix}__ico-down`}
                  responsive
                  activeWildcard={false}
                  subMenuClickBreakpoint="tablet"
                />
              )}

              <div
                className={classNames({
                  [`${cssPrefix}__header--search`]: true,
                })}
                ref={searchRef}
              >
                <SimpleSearch
                  label=""
                  placeholder="What are you looking for"
                  url="/search"
                  submitLabel=""
                  onSubmit={onSearchSubmit}
                />
                <Button
                  type="button"
                  label=""
                  srLabel="Search"
                  onClick={onSearchClick}
                  extraClassNames={{
                    [`${cssPrefix}__header--search-button`]: true,
                  }}
                  ampOn="tap:AMP.navigateTo(url='/search')"
                />
              </div>
            </div>
          )}
        </div>
      </header>
      {!isLandingPage && (
        <div className={`${cssPrefix}__productselector-container`}>
          <ProductSelector />
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  context: PropTypes.shape().isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default withPolaris(Header);
